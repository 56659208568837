import React from "react";
import LabTest from "./LabTest";

class RadioOptionModel {
  constructor(Value, Label, Checked, OthText) {
    this.value = Value || 0;
    this.label = Label || "";
    this.checked = Checked || false;
    this.oth_text = OthText || "";
  }
}

const LabTable = ({ data, HandleDateChange, HandleFieldChange, indexDate, indexDtMinus6mo }) => {
  return (
    <div className="lab-table-container">
      <table className="lab-table">
        <thead>
            <tr>
              <th className="Lab-table-text" style={{ width: "10%" }}>Component</th>
              <th className="Lab-table-text" style={{ width: "10%" }}>Check if present in patient's chart</th>
              <th className="Lab-table-text" style={{ width: "15%" }}>Date of lab collection</th>
              <th className="Lab-table-text" style={{ width: "20%" }}>
                Lab result
              </th>
              <th className="Lab-table-text" style={{ width: "30%" }}>
                Reference range
              </th>
              <th className="Lab-table-text" style={{ width: "20%" }}>Unit</th>
            </tr>
        </thead>
        <tbody>

          <LabTest
            labRowValues={data?.[0]}
            component={'White blood cell count'}
            options={[
              new RadioOptionModel(1, ["x10", <sup>6</sup>, "/L"], false, ""),
              new RadioOptionModel(2, ["x10", <sup>9</sup>, "/L"], false, ""),
              new RadioOptionModel(3, ["Thous/mcl"], true, ""),
              new RadioOptionModel(4, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />


          <LabTest component={'Hemoglobin count'}
            labRowValues={data?.[1]}
            options={[
              new RadioOptionModel(1, ["g/dL"], false, ""),
              new RadioOptionModel(2, ["g/L"], false, ""),
              new RadioOptionModel(3, ["mmol/L"], true, ""),
              new RadioOptionModel(4, ["mg/dl"], true, ""),
              new RadioOptionModel(5, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[2]}
            component={'Platelet count'}
            options={[
              new RadioOptionModel(1, ["xThous/mcl"], false, ""),
              new RadioOptionModel(2, ["x10", <sup>3</sup>, "mm", <sup>3</sup>], false, ""),
              new RadioOptionModel(3, ["x10", <sup>6</sup>, "/L"], false, ""),
              new RadioOptionModel(4, ["x10", <sup>9</sup>, "/L"], false, ""),
              new RadioOptionModel(5, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[3]}
            component={'Absolute lymphocyte count'}
            options={[
              new RadioOptionModel(1, ["x10", <sup>9</sup>, "/L"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[4]}
            component={'Absolute monocyte count'}
            options={[
              new RadioOptionModel(1, ["x10", <sup>9</sup>, "/L"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[5]}
            component={'Absolute neutrophil count'}
            options={[
              new RadioOptionModel(1, ["x10", <sup>9</sup>, "/L"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[6]}
            component={'Bilirubin'}
            options={[
              new RadioOptionModel(1, ["mg/dL"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <td colSpan="6">
              <b>Other Labs</b>
            </td>
          </tr>
          <LabTest
            labRowValues={data?.[7]}
            component={'Lactate dehydrogenase'}
            options={[
              new RadioOptionModel(1, ["U/L"], false, ""),
              new RadioOptionModel(2, ["µkat/L"], false, ""),
              new RadioOptionModel(3, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[8]}
            component={'Beta-2 microglobulin level'}
            options={[
              new RadioOptionModel(1, ["µg/dL"], false, ""),
              new RadioOptionModel(2, ["mg/L"], false, ""),
              new RadioOptionModel(3, ["nmol/L"], false, ""),
              new RadioOptionModel(4, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[9]}
            component={'ZAP70'}
            options={[
              new RadioOptionModel(1, ["%"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[10]}
            component={'Serum creatinine'}
            options={[
              new RadioOptionModel(1, ["mg/dL"], false, ""),
              new RadioOptionModel(2, ["μmol/L"], false, ""),
              new RadioOptionModel(3, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[11]}
            component={'Serum albumin'}
            options={[
              new RadioOptionModel(1, ["g/L"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[12]}
            component={'Calcium'}
            options={[
              new RadioOptionModel(1, ["mg/dL"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[13]}
            component={'Estimated glomerular filtration rate (eGFR)'}
            options={[
              new RadioOptionModel(1, ["mL/min/1.73m2"], false, ""),
              new RadioOptionModel(2, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
          <LabTest
            labRowValues={data?.[14]}
            component={'Creatinine clearance'}
            options={[
              new RadioOptionModel(1, ["mL/min"], false, ""),
              new RadioOptionModel(2, ["mL/sec"], false, ""),
              new RadioOptionModel(3, ["Other:"], true, ""),
              new RadioOptionModel(99, ["Unknown"], false, ""),
            ]}
            HandleDateChange={HandleDateChange}
            HandleFieldChange={HandleFieldChange}
            indexDate={indexDate} 
            indexDtMinus6mo={indexDtMinus6mo}
          />
        </tbody>
      </table>
    </div>
  );
};

export default LabTable;
