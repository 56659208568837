import WebServiceWrapper from "./WebServiceWrapper";

export const AbstractorService = {
  GetAllAbstractors, AddAbstractor
  // ,UpdateAbstractor
  ,DeleteAbstractor
};

function GetAllAbstractors() {
  return WebServiceWrapper.Get(`/abstractor/GetAllAbstractors`);
}
function AddAbstractor(payload) {
  return WebServiceWrapper.Post("/abstractor/AddAbstractor",payload);
}
// function UpdateAbstractor(payload) {
//   return WebServiceWrapper.Post("/abstractor/UpdateAbstractor",payload);
// }
function DeleteAbstractor(abstractorId) {
  return WebServiceWrapper.Get(`/abstractor/DeleteAbstractor?abstractorId=${abstractorId}`);
}


