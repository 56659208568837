import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import {
  PageDesignConstants,
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
  DefaultDate,
  SupportiveCareEpcoritamab,
  MedicationType,
} from "../../../Helpers/Enums";
import SideBarMenu from "../../../Components/SideBarMenu";
import { Dropdown } from "react-bootstrap";
import ErrorField from "../../../Components/ErrorField";
//import { CommonError_Msgs, demographicScreen_Msgs } from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  AllowOnlyIntegers,
  GetDMUID,
  GetDisplayDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  GetOrdinalInWord,
  IsAfterIndex,
  IsDateBefore,
  IsDateEmpty,
  IsDateValid,
  IsValidDate2,
  Is_DateBefore,
  ParseDate,
  SetDateState,
  ValueInRange,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import DateControl from "../../../Components/DateControl";
import {
  CommonError_Msgs,
  CommonMsgs,
  Epcoritamab_Msgs,
  HRU_MSGS,
} from "../../../Helpers/HelperText";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";

const Epcoritamab = () => {
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  // const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  // const surveyDate = patientData?.surveyDate ?? "";
  const userId = GetLoggedInUserID() ?? 0;
  const pid = GetLocalStorageData("patientId") ?? 0;
  const patientId = GetLocalStorageData("patientId");

  const [dropdownValueChanged, setDropdownValueChanged] = useState(false);
  const [index_date, setIndexDate] = useState("");
  const [chartAbstractionDate, setChartAbstractionDate] = useState("");
  const [death_Date, set_Death_Date] = useState("");

  // State to manage visibility of Q46 based on LYRIC or Lugano selection
  const [showQ46, setShowQ46] = useState([]);
  const {
    ShowToast,
    ToggleLoader,
    ShowActionPopUp,
    HandleSessionTimeout,
    appState,
  } = useContext(AppContext);

  //New Chanegs Q50.

  const [otherInput, setOtherInput] = useState("");
  const [otherInputs, setOtherInputs] = useState({});
  const [subOtherInputs, setSubOtherInputs] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    parent: {},
    sub: {},
    subSub: {},
  });

  //   const initialSelections = (patientTreatment, treatmentMedicationBridges) => {
  //     debugger
  //     if (
  //       patientTreatment.medicationNone === null &&
  //       patientTreatment.medicationUnk === null
  //     ) {
  //       const newSelectedOptions = { parent: {}, sub: {}, subSub: {} };
  //       const newOtherInputs = { parent: "", sub: "" };

  //       treatmentMedicationBridges.forEach((bridge) => {
  //         newSelectedOptions.parent[bridge.medicationTypeID] = true;
  //         // treatmentMedicationBridgeId: bridge.treatmentMedicationBridgeId;

  //         if (bridge.medicationTypeID === 8 && bridge.medicationTypeOth) {
  //           newOtherInputs.parent = bridge.medicationTypeOth;
  //           setOtherInput(bridge.medicationTypeOth);
  //           // setSubOtherInput(value);
  //         }

  //         if (bridge.subMedicationTypeId) {
  //           if (!newSelectedOptions.sub[bridge.medicationTypeID]) {
  //             newSelectedOptions.sub[bridge.medicationTypeID] = {};
  //           }
  //           newSelectedOptions.sub[bridge.medicationTypeID][
  //             bridge.subMedicationTypeId
  //           ] = true;

  //           if (bridge.subMedicationTypeId === 6 && bridge.subMedicationTypeOth) {
  //             newOtherInputs.sub = bridge.subMedicationTypeOth;
  //             setSubOtherInputs(bridge.subMedicationTypeOth);
  //           }

  //         }

  //         if (bridge.subSubMedicationTypeId) {
  //           if (!newSelectedOptions.subSub[bridge.subMedicationTypeId]) {
  //             newSelectedOptions.subSub[bridge.subMedicationTypeId] = {};
  //           }
  //           newSelectedOptions.subSub[bridge.subMedicationTypeId][
  //             bridge.subSubMedicationTypeId
  //           ] = true;
  //         }

  //         if (bridge.treatmentMedicationBridgeId) {
  //           newSelectedOptions.treatmentMedicationBridgeId = bridge.treatmentMedicationBridgeId || 0;
  //         }

  //       });
  // debugger;
  //       setSelectedOptions(newSelectedOptions);
  //       setOtherInputs(newOtherInputs);
  //     } else {
  //       if (patientTreatment.medicationNone) {
  //         setRadioSelection("None");
  //       } else {
  //         setRadioSelection("Unknown");
  //       }
  //     }
  //   };

  // State to manage visibility of Q47 based on LYRIC or Lugano selection
  // const [showQ47, setShowQ47] = useState([]);

  // const initialSelections = (patientTreatment, treatmentMedicationBridges) => {
  //   if (patientTreatment.medicationNone === null && patientTreatment.medicationUnk === null) {
  //     const newSelectedOptions = { parent: {}, sub: {}, subSub: {} };
  //     const newOtherInputs = { parent: "", sub: "" };

  //     treatmentMedicationBridges.forEach((bridge) => {
  //       const { medicationTypeID, subMedicationTypeId, subSubMedicationTypeId } = bridge;

  //       // Set the parent selection and store its bridge IDs in an array for each parent ID
  //       if (!newSelectedOptions.parent[medicationTypeID]) {
  //         newSelectedOptions.parent[medicationTypeID] = [];
  //       }
  //       newSelectedOptions.parent[medicationTypeID].push({
  //         isSelected: true,
  //         // bridge
  //       });

  //       if (medicationTypeID === 8 && bridge.medicationTypeOth) {
  //         newOtherInputs.parent = bridge.medicationTypeOth;
  //         setOtherInput(bridge.medicationTypeOth);
  //       }

  //       // Set the sub selection if it exists and store its bridge IDs in an array for each combination
  //       if (subMedicationTypeId) {
  //         if (!newSelectedOptions.sub[medicationTypeID]) {
  //           newSelectedOptions.sub[medicationTypeID] = {};
  //         }
  //         if (!newSelectedOptions.sub[medicationTypeID][subMedicationTypeId]) {
  //           newSelectedOptions.sub[medicationTypeID][subMedicationTypeId] = [];
  //         }
  //         newSelectedOptions.sub[medicationTypeID][subMedicationTypeId].push({
  //           isSelected: true,

  //         });

  //         if (subMedicationTypeId === 6 && bridge.subMedicationTypeOth) {
  //           newOtherInputs.sub = bridge.subMedicationTypeOth;
  //           setSubOtherInputs(bridge.subMedicationTypeOth);
  //         }
  //       }

  //       // Set the sub-sub selection if it exists and store its bridge IDs in an array for each combination
  //       if (subSubMedicationTypeId) {
  //         if (!newSelectedOptions.subSub[subMedicationTypeId]) {
  //           newSelectedOptions.subSub[subMedicationTypeId] = {};
  //         }
  //         if (!newSelectedOptions.subSub[subMedicationTypeId][subSubMedicationTypeId]) {
  //           newSelectedOptions.subSub[subMedicationTypeId][subSubMedicationTypeId] = [];
  //         }
  //         newSelectedOptions.subSub[subMedicationTypeId][subSubMedicationTypeId].push({
  //           isSelected: true,

  //         });
  //       }
  //     });

  //     // Set the selected options and other inputs in state
  //     setSelectedOptions(newSelectedOptions);
  //     setOtherInputs(newOtherInputs);
  //   } else {
  //     // Handle "None" or "Unknown" selections
  //     if (patientTreatment.medicationNone) {
  //       setRadioSelection("None");
  //     } else {
  //       setRadioSelection("Unknown");
  //     }
  //   }
  // };

  const navigatePaths = {
    prevPage: "/Demographics",
    currPage: "/Epcoritamab",
    nextPage:
      appState.tmtPriorEpcoLOTCnt ?? 0 > 0
        ? "/TreatmentsPriorEpcoritamab/1"
        : "/CARTCellTherapy",
  };

  const InitialSoftErrList = {
    q38Unk_err: false,
    q39Unk_err: false,
    q40Unk_err: false,
    q41Unk_err: false,
    q42Unk_err: false,
    epco_discont_unk_err: false,
    dosesOfTocilizumabNumOfDosesUnk_err: false,
    epco_parent_unknown: false, //q51 unk soft err
    epco_doses_of_tocilizumab_unknown: false,
    receivedAdditionaltmtSoft:false,
  };

  const InitialHardErrList = {
    q36Empty: false,
    q37Empty: false,
    q37EmptyagentTxt: false,
    q37EmptyOtherTxt: false,

    q38Empty: false,
    q39Empty: false,
    q38SizeOutOfRange: false,
    q39SizeOutOfRange: false,

    q40Empty: false,
    q41Empty: false,
    q42Empty: false,
    q42txtEmpty: false,
    q41_date_afterIndex: false,
    q41_date_beforeDeathorChart: false,
    SiteOfCareEmpty: false,
    //43
    epco_discont_reasonEmpty: false,
    epco_discont_reasonEmpty_txtEmpty: false,

    //Q50. checkbox hard error validation
    // selectedOptions_Empty: false,
    // selectedOptions_Sub_Empty: false,
    // selectedOptions_SubSub_Empty: false,
    epco_parent_empty: false,
    epco_parent_othertext_empty: false,

    // [SupportiveCareEpcoritamab.Dexamethasone]: false,
    // [SupportiveCareEpcoritamab.Hydrocortisone]: false,
    // [SupportiveCareEpcoritamab.Prednisone]: false,
    // [SupportiveCareEpcoritamab.Prednisolone]: false,
    // [SupportiveCareEpcoritamab.Methylprednisolone]: false,
    // [SupportiveCareEpcoritamab.Other]: false,
    // epco_sub_othertext_empty: false,

    //Q51. hard error message
    dosesOfTocilizumabNumOfDoses_Empty: false,

    //59
    receivedAdditionaltmtEmpty:false,
  };

  const EPCORAssesmentRow = {
    epcoritamabradiologicalassessmentid: 0, // pk of child table

    //Question 43
    radiological_assess_dx: null,
    radiological_assess_dx_date: DefaultDate,

    q43afterindexEmptyHard: false,
    q43beforeQ41dateEmptyHard: false,

    radiologyDxDateUnkSoft: false,
    radiologyDxEmptyHard: false,

    q44_LBCL_status: null,
    q44_lbcl_oth_txt: "",

    q44StatusUnkSoft: false,
    q44StatusEmptyHard: false,
    q44lbclTxtEmptyHard: false,

    //question 45
    q45_stage_status: null,
    q45statusEmptyHard: false,

    //question 46
    q46LyricAssess: null,
    q46LyricEmptyHard: false,
    q46UnkSoft: false,

    //question 47
    q47_radiology_type: null,
    q47RadioAssessTypeOtherTxt: "",
    q47radiologyTypeUnkSoft: false,
    q47radiologyTypeEmptyHard: false,
    q47radiologyTypeTxtEmptyHard: false,

    //SiteOfCare
    siteOfCare_rd: null,
    siteOfCare_other: "",
  };
  const [epcorAssessmentsDetails, setEPCORAssessmentsDetails] = useState([
    EPCORAssesmentRow,
  ]);

  //Q43-Q48 EPCOR Assessment Repeater
  const DeleteEPCORAssessmentRow = (id) => {
    const newArray = [...epcorAssessmentsDetails];
    newArray.splice(id, 1);
    setEPCORAssessmentsDetails(newArray);
  };

  const AddEPCORAssessmentRow = () => {
    setEPCORAssessmentsDetails((prev) => [
      ...prev,
      {
        ...EPCORAssesmentRow,
      },
    ]);
  };

  //Repeater
  const HandleRepeaterDateChange = (name, date, datePart) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    switch (name) {
      case "radiological_dx_date-" + id:
        {
          let updatedArray = [...epcorAssessmentsDetails];
          const idx = name.slice(lastIndex + 1);
          updatedArray[id].radiological_assess_dx_date = SetDateState(
            updatedArray[id].radiological_assess_dx_date,
            date,
            datePart
          );
          updatedArray[id].radiological_assess_dx = 1;

          updatedArray[id].radiologyDxEmptyHard = false;
          updatedArray[id].radiologyDxDateUnkSoft = false;

          updatedArray[id].q43afterindexEmptyHard = false;
          updatedArray[id].q43beforeQ41dateEmptyHard = false;
          setEPCORAssessmentsDetails(updatedArray);
        }
        break;
      default:
        break;
    }
  };

  const DoseOfTocilizumabRow = {
    epcoritamabtreatmentsitecarebridgeid: 0,
    siteOfCare_rd: null,
    siteOfCare_other: "",
    siteOfCare_rd_empty: false,
    siteOfCare_other_empty: false,
    siteOfCare_other_unk_soft: false,
  };

  const DeleteDoseOfTocilizumabRow = (id) => {
    const newArray = [...doseOfTocilizumabDetails];
    newArray.splice(id, 1);
    setDoseOfTocilizumabDetails(newArray);
  };

  const AddDoseOfTocilizumabRow = () => {
    setDoseOfTocilizumabDetails((prev) => [
      ...prev,
      {
        ...DoseOfTocilizumabRow,
      },
    ]);
  };

  const [doseOfTocilizumabDetails, setDoseOfTocilizumabDetails] = useState([
    DoseOfTocilizumabRow,
  ]);

  // const GlucocorticoidsRow={
  //   //Q51
  //   supportive_careRd:null,
  //   supportive_care_date:DefaultDate,
  //   supportive_careRdEmpty:false,
  //   supportive_care_dtEmpty:false,

  //   //52
  //   dosage_quantityRd: null,
  //   dosage_quantity_txt:"",
  //   dosage_quantityRdEmpty:false,
  //   dosage_quantity_txtEmpty:false,

  //   //53
  //   dosefrequencyRd:null,
  //   dosefrequency_oth_txt:"",
  //   dosefrequencyRdEmpty:false,
  //   dosefrequency_oth_txtEmpty:false,

  //   //54
  //   durationprescribedRd:null,
  //   durationprescribed_txt:"",
  //   durationprescribedRdEmpty:false,
  //   durationprescribed_txtEmpty:false,
  //   }
  // const[dexamethasoneOralDetails, setDexamethasoneOralDetails] = useState([GlucocorticoidsRow])

  // const DeleteDexamethasoneOralDetailsRow = (id) => {
  //   const newArray = [...dexamethasoneOralDetails];
  //   newArray.splice(id, 1)
  //   setDexamethasoneOralDetails(newArray);
  // }

  // const AddDexamethasoneOralDetailsRow = () => {

  //   setDexamethasoneOralDetails((prev) => [
  //       ...prev,
  //       {
  //           ...GlucocorticoidsRow
  //       },
  //   ]);
  // }

  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);

    switch (name) {
      //43
      case "radiological_assess_dx" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].radiological_assess_dx = value_int;
        updatedArray[id].radiological_assess_dx_date = DefaultDate;

        updatedArray[id].radiologyDxDateUnkSoft = value_int === 99;
        updatedArray[id].radiologyDxEmptyHard = false;

        updatedArray[id].q43afterindexEmptyHard = false;
        updatedArray[id].q43beforeQ41dateEmptyHard = false;

        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      //44
      case "q44_LBCL_status" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q44_LBCL_status = value_int;
        updatedArray[id].q44_lbcl_oth_txt = "";

        updatedArray[id].q44StatusUnkSoft = value_int === 99;
        updatedArray[id].q44StatusEmptyHard = false;
        updatedArray[id].q44lbclTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      case "q44_lbcl_oth_txt": {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q44_LBCL_status = 5;
        updatedArray[id].q44_lbcl_oth_txt = value;

        updatedArray[id].q44StatusUnkSoft = value_int === 99;
        updatedArray[id].q44StatusEmptyHard = false;
        updatedArray[id].q44lbclTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }
      //45
      case "q45_stage_status" + id: {
        let updatedArray = [...epcorAssessmentsDetails];

        // Reset dependent fields when q45_stage_status changes
        if (value_int === 1 || value_int === 3) {
          updatedArray[id].q46LyricAssess = null;
          // updatedArray[id].q47_radiology_type = null;
          // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

          setShowQ46((prev) => ({ ...prev, [id]: true }));
          // setShowQ47((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 4 || value_int === 99) {
          updatedArray[id].q46LyricAssess = null;
          // updatedArray[id].q47_radiology_type = null;
          // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

          setShowQ46((prev) => ({ ...prev, [id]: false }));
          // setShowQ47((prev) => ({ ...prev, [id]: true }));
        }

        updatedArray[id].q45_stage_status = value_int;
        updatedArray[id].q46UnkSoft = value_int === 99;
        updatedArray[id].q45statusEmptyHard = false;
        updatedArray[id].q46LyricEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);

        // Determine visibility for Q46 and Q47 based on q45_stage_status value
        if (value_int === 1 || value_int === 3) {
          setShowQ46((prev) => ({ ...prev, [id]: true }));
          //  setShowQ47((prev) => ({ ...prev, [id]: false }));
        } else if (value_int === 2 || value_int === 4) {
          setShowQ46((prev) => ({ ...prev, [id]: false }));
          //  setShowQ47((prev) => ({ ...prev, [id]: true }));
        }

        break;
      }

      //46
      case "q46LyricAssess" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q46LyricAssess = value_int;

        updatedArray[id].q46LyricEmptyHard = false;

        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      //47
      case "q47_radiology_type" + id: {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q47_radiology_type = value_int;
        updatedArray[id].q47RadioAssessTypeOtherTxt = "";

        updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
        updatedArray[id].q47radiologyTypeEmptyHard = false;
        updatedArray[id].q47radiologyTypeTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      case "q47RadioAssessTypeOtherTxt": {
        let updatedArray = [...epcorAssessmentsDetails];
        updatedArray[id].q47_radiology_type = 5;
        updatedArray[id].q47RadioAssessTypeOtherTxt = value;

        updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
        updatedArray[id].q47radiologyTypeEmptyHard = false;
        updatedArray[id].q47radiologyTypeTxtEmptyHard = false;
        setEPCORAssessmentsDetails(updatedArray);
        break;
      }

      //52 repeater

      case "siteOfCare_rd" + id: {
        let updatedArray = [...doseOfTocilizumabDetails];

        updatedArray[id].siteOfCare_rd = Number(value);

        updatedArray[id].siteOfCare_other_unk_soft = value_int === 99;
        updatedArray[id].siteOfCare_other = "";

        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }

      case "siteOfCare_other": {
        let updatedArray = [...doseOfTocilizumabDetails];
        updatedArray[id].siteOfCare_rd = 4;
        updatedArray[id].siteOfCare_other = value;

        updatedArray[id].siteOfCare_other_unk_soft = false;
        updatedArray[id].siteOfCare_rd_empty = false;
        updatedArray[id].siteOfCare_other_empty = false;
        setDoseOfTocilizumabDetails(updatedArray);
        break;
      }

      //New changes Q39
      case "stepupdosedaysdifferenceRd" + id: {
        let updatedArray = [...epcostepupdoseDetails];
        updatedArray[id].stepupdosedaysdifferenceRd = Number(value);
        updatedArray[id].stepupdosedaysdifferenceTxt = "";

        // updatedArray[id].siteOfCare_other_unk_soft = false;
        updatedArray[id].stepupdosedaysdifferenceRdEmpty = false;
        updatedArray[id].stepupdosedaysdifferenceTxtRange = false;

        updatedArray[id].stepupdosedaysdifferencetxtEmpty = false;
        updatedArray[id].stepupdosedaysdifferenceUnkSoft =
          Number(value) === 99 ? true : false;
        setEpcostepupdoseDetails(updatedArray);
        break;
      }

      case "stepupdosedaysdifferenceTxt" + id: {
        let updatedArray = [...epcostepupdoseDetails];
        updatedArray[id].stepupdosedaysdifferenceTxt = value;
        updatedArray[id].stepupdosedaysdifferenceRd = 1;

        // updatedArray[id].siteOfCare_other_unk_soft = false;
        updatedArray[id].stepupdosedaysdifferenceRdEmpty = false;
        updatedArray[id].stepupdosedaysdifferenceTxtRange = false;
        updatedArray[id].stepupdosedaysdifferencetxtEmpty = false;
        updatedArray[id].stepupdosedaysdifferenceUnkSoft = false;
        setEpcostepupdoseDetails(updatedArray);
        break;
      }

      //New change site of care loop
      case "siteofcare_doseRd" + id: {
        let updatedArray = [...stepupdoseAssessment];
        updatedArray[id].siteofcare_doseRd = Number(value);
        updatedArray[id].stepupdosedaysdifferenceRdEmpty = false;
        updatedArray[id].stepupdosedaysdifferenceTxtRange = false;

        updatedArray[id].siteofcare_doseRdEmpty = false;

        setStepupdoseAssessment(updatedArray);
        break;
      }

      default:
        break;
    }
  };

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const [epcoritamabDetails, setepcoritamabDetails] = useState({
    selectedLOT: null,
    selectedLOTCheck: null,
    q36_state: null,
    q37Mono: null,
    q37Lena: false,
    q37Lonca: false,
    q37Ritux: false,
    q37Vene: false,
    q37InvestAgent: false,
    q37InvestAgent_txt: "",
    q37Other: false,
    q37Other_txt: "",
    q38step_up: null,
    q38_days_txt: "",
    q38_step_up_txt: null,
    q38Unk: null,
    q39step_up: null,
    q39_days_txt: "",
    q40Discont: null,
    q41EpDiscontDtUnk: null,
    dt_discont: DefaultDate,
    q42DiscontReason: null,

    //40
    siteOfCare_Dose1: null,
    siteOfCare_Dose2: null,
    siteOfCare_FullDose: null,

    //43

    epcoDiscontLbcl: false,
    epcoDiscontEligibility: false,
    epcoDiscontTransplant: false,
    epcoDiscontCompleteResponse: false,
    epcoDiscontInadequateResponse: false,
    epcoDiscontCovid19Infection: false,
    epcoDiscontCartTherapy: false,
    epcoDiscontPatientChoiceDecision: false,
    epcoDiscontOther: false,
    epcoDiscontUnknown: false,
    epcoDiscontReasonOtherTxt: "",

    //51
    dosesOfTocilizumabNumOfDoses: null,

    //50
    medicationNone: false,
    medicationUnk: false,

    //59
    receivedAdditionaltmt:null
  });

  const ValidateForm = () => {
    let isValid = true;
    //q36

    if (epcoritamabDetails.q36_state === null) {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: true,
      }));
      isValid = false;
    } else if (
      epcoritamabDetails.q36_state === 1 &&
      epcoritamabDetails.selectedLOT === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q36Empty: false,
      }));
    }

    // Validation for q37
    const {
      q37Lena,
      q37Lonca,
      q37Vene,
      q37Ritux,
      q37InvestAgent,
      q37Other,
      q37InvestAgent_txt,
      q37Other_txt,
      q37Mono,
    } = epcoritamabDetails;

    if (
      !q37Lena &&
      !q37Lonca &&
      !q37Vene &&
      !q37Ritux &&
      !q37InvestAgent &&
      !q37Other &&
      q37Mono !== 1
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q37Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q37Empty: false,
      }));
    }

    if (q37Other && q37Other_txt.trim() === "") {
      setHardErrList((prev) => ({
        ...prev,
        q37EmptyOtherTxt: true,
      }));
      isValid = false;
    }
    if (q37InvestAgent && q37InvestAgent_txt.trim() === "") {
      setHardErrList((prev) => ({
        ...prev,
        q37EmptyagentTxt: true,
      }));
      isValid = false;
    }

    //Question 38 validation
    if (epcoritamabDetails.q38step_up === null) {
      setHardErrList((prev) => ({
        ...prev,
        q38Empty: true,
      }));
      isValid = false;
    } else if (epcoritamabDetails.q38step_up === 1) {
      if (epcoritamabDetails.q38_step_up_txt === "") {
        setHardErrList((prev) => ({
          ...prev,
          q38Empty: true,
        }));
        isValid = false;
      }
    }

    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q38Empty: false,
    //   }));
    // }

    // question 39

    if (
      epcoritamabDetails.q38step_up != 99 &&
      epcoritamabDetails.q39step_up === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q39Empty: true,
      }));
      isValid = false;
    }
    // else if (
    //   epcoritamabDetails.q39step_up === 1 &&
    //   epcoritamabDetails.q39_days_txt === ""
    // ) {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q39Empty: true,
    //   }));
    //   isValid = false;
    // }

    if (
      epcoritamabDetails.q38step_up != 99 &&
      epcoritamabDetails.q39step_up === 1
    ) {
      if (epcoritamabDetails.q39_days_txt === "") {
        setHardErrList((prev) => ({
          ...prev,
          q39Empty: true,
        }));
        isValid = false;
      } else if (
        !ValueInRange(parseInt(epcoritamabDetails.q39_days_txt), 1, 30)
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q39SizeOutOfRange: true,
        }));
        isValid = false;
      }
    }
    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q39Empty: false,
    //   }));
    // }

    //q40 SiteOfCare Validation

    if (
      epcoritamabDetails.q38step_up !== 99 &&
      epcoritamabDetails.siteOfCare_FullDose === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        SiteOfCareEmpty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        SiteOfCareEmpty: false,
      }));
    }

    //question 40 validate

    if (epcoritamabDetails.q40Discont === null) {
      setHardErrList((prev) => ({
        ...prev,
        q40Empty: true,
      }));
      isValid = false;
    } else if (
      epcoritamabDetails.q40Discont === 0 ||
      epcoritamabDetails.q40Discont === 99
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: false,
        q42Empty: false,
      }));
    } else {
      setHardErrList((prev) => ({
        ...prev,
        q40Empty: false,
      }));
    }

    //validation for 41

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.q41EpDiscontDtUnk === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: true,
      }));
      isValid = false;
    }

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.q41EpDiscontDtUnk === 1 &&
      IsDateEmpty(epcoritamabDetails.dt_discont)
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: true,
      }));
      isValid = false;
    }

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.q41EpDiscontDtUnk === 1 &&
      IsDateValid(epcoritamabDetails.dt_discont)
    ) {
      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        IsDateValid(epcoritamabDetails.dt_discont) &&
        (!IsDateEmpty(chartAbstractionDate) || !IsDateEmpty(death_Date)) &&
        !IsDateBefore(
          epcoritamabDetails.dt_discont,
          chartAbstractionDate,
          death_Date
        )
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_beforeDeathorChart: true,
        }));
        isValid = false;
      }
    } else if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoritamabDetails.q41EpDiscontDtUnk === 1 &&
      epcoritamabDetails.dt_discont === DefaultDate
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q41Empty: true,
      }));
      isValid = false;
    }

    if (epcoritamabDetails.q40Discont === 1 && epcoritamabDetails.dt_discont) {
      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        IsDateValid(epcoritamabDetails.dt_discont) &&
        !IsDateEmpty(index_date) &&
        !IsAfterIndex(epcoritamabDetails.dt_discont, index_date)
      ) {
        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_afterIndex: true,
        }));
        isValid = false;
      }
    }

    // else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q41Empty: false,
    //     }));
    // }

    //question 42 //q42DiscontReasonOtherTxt
    // if (
    //     epcoritamabDetails.q40Discont === 1 &&
    //     epcoritamabDetails.q42DiscontReason === null
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q42Empty: true,
    //     }));
    //     isValid = false;
    // } else if (
    //     epcoritamabDetails.q40Discont === 1 &&
    //     epcoritamabDetails.q42DiscontReason === 10 &&
    //     epcoritamabDetails.q42DiscontReasonOtherTxt === ""
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    // q42txtEmpty: true,
    //     }));
    //     isValid = false;
    // } else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    // q42txtEmpty: false,
    //     }));
    // }

    const {
      epcoDiscontLbcl,
      epcoDiscontEligibility,
      epcoDiscontTransplant,
      epcoDiscontCompleteResponse,
      epcoDiscontInadequateResponse,
      epcoDiscontCovid19Infection,
      epcoDiscontCartTherapy,
      epcoDiscontPatientChoiceDecision,
      epcoDiscontOther,
      epcoDiscontUnknown,
      epcoDiscontReasonOtherTxt,
    } = epcoritamabDetails;

    if (epcoritamabDetails.q40Discont === 1) {
      if (
        !epcoDiscontLbcl &&
        !epcoDiscontEligibility &&
        !epcoDiscontTransplant &&
        !epcoDiscontCompleteResponse &&
        !epcoDiscontInadequateResponse &&
        !epcoDiscontCovid19Infection &&
        !epcoDiscontCartTherapy &&
        !epcoDiscontPatientChoiceDecision &&
        !epcoDiscontUnknown &&
        !epcoDiscontOther
      ) {
        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: true,
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
        }));
      }
    }

    if (
      epcoritamabDetails.q40Discont === 1 &&
      epcoDiscontOther &&
      epcoDiscontReasonOtherTxt.trim() === ""
    ) {
      setHardErrList((prev) => ({
        ...prev,
        epco_discont_reasonEmpty_txtEmpty: true,
      }));
      isValid = false;
    }

    // if (epcoritamabDetails.dosesOfTocilizumabNumOfDoses === null) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //       //  q36Empty: true,
    //     }));
    //     isValid = false;
    // } else if (
    //     epcoritamabDetails.q36_state === 1 &&
    //     epcoritamabDetails.selectedLOT === null
    // ) {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q36Empty: true,
    //     }));
    //     isValid = false;
    // } else {
    //     setHardErrList((prev) => ({
    //         ...prev,
    //         q36Empty: false,
    //     }));
    // }

    //repeater
    if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
      const updatedArray = epcorAssessmentsDetails.map((item) => {
        let radiologyDxEmptyHard = false;
        if (
          item.radiological_assess_dx == null ||
          (item.radiological_assess_dx === 1 &&
            IsDateEmpty(item.radiological_assess_dx_date))
        ) {
          radiologyDxEmptyHard = true;
        }

        let q43afterindexEmptyHard = false;
        if (
          IsDateValid(item.radiological_assess_dx_date) &&
          IsDateValid(index_date)
        ) {
          q43afterindexEmptyHard =
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateEmpty(index_date) &&
            !IsAfterIndex(item.radiological_assess_dx_date, index_date);
        }

        let q43beforeQ41dateEmptyHard = false;
        if (
          IsDateValid(item.radiological_assess_dx_date) &&
          IsDateValid(epcoritamabDetails.dt_discont)
        ) {
          q43beforeQ41dateEmptyHard =
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateEmpty(epcoritamabDetails.dt_discont) &&
            !Is_DateBefore(
              item.radiological_assess_dx_date,
              epcoritamabDetails.dt_discont
            );
        }
        const q44StatusEmptyHard = item.q44_LBCL_status == null;
        const q44lbclTxtEmptyHard = q44StatusEmptyHard
          ? false
          : item.q44_LBCL_status === 5 && isStringEmpty(item.q44_lbcl_oth_txt);

        const q45statusEmptyHard = item.q45_stage_status == null;

        const q46LyricEmptyHard =
          (item.q45_stage_status === 1 || item.q45_stage_status === 3) &&
          item.q46LyricAssess === null
            ? true
            : false;

        const q47radiologyTypeEmptyHard =
          // (item.q45_stage_status === 2 || item.q45_stage_status === 4) &&
          item.q47_radiology_type == null ? true : false;
        const q47radiologyTypeTxtEmptyHard = q47radiologyTypeEmptyHard
          ? false
          : item.q47_radiology_type === 5 &&
            isStringEmpty(item.q47RadioAssessTypeOtherTxt);

        if (
          radiologyDxEmptyHard ||
          (item.radiological_assess_dx == 1 &&
            !IsDateValid(item.radiological_assess_dx_date)) ||
          q44StatusEmptyHard ||
          q44lbclTxtEmptyHard ||
          q45statusEmptyHard ||
          q46LyricEmptyHard ||
          q47radiologyTypeEmptyHard ||
          q47radiologyTypeTxtEmptyHard ||
          q43afterindexEmptyHard ||
          q43beforeQ41dateEmptyHard
        ) {
          isValid = false;
        }

        return {
          ...item,
          radiologyDxDateUnkSoft: false,
          radiologyDxEmptyHard,

          q44StatusUnkSoft: false,
          q44StatusEmptyHard,
          q44lbclTxtEmptyHard,
          q45statusEmptyHard,

          q46LyricEmptyHard,
          q46UnkSoft: false,

          q47radiologyTypeUnkSoft: false,
          q47radiologyTypeEmptyHard,
          q47radiologyTypeTxtEmptyHard,

          q43afterindexEmptyHard,
          q43beforeQ41dateEmptyHard,
        };
      });
      setEPCORAssessmentsDetails(updatedArray);
    }

    //repeater

    if (
      // selectedOptions.parent[6] === true
      tocilizumabChecked &&
      epcoritamabDetails.dosesOfTocilizumabNumOfDoses === null
    ) {
      setHardErrList((prev) => ({
        ...prev,
        dosesOfTocilizumabNumOfDoses_Empty: true,
      }));
      isValid = false;
    }
    if (
      // selectedOptions.parent[6] === true
      tocilizumabChecked &&
      epcoritamabDetails.dosesOfTocilizumabNumOfDoses === 1
    ) {
      if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
        const updatedArray = doseOfTocilizumabDetails.map((item) => {
          let siteOfCare_rd_empty = false;
          if (
            //if tocilizumab selected and
            item.siteOfCare_rd == null
          ) {
            siteOfCare_rd_empty = true;
          }

          let siteOfCare_other_empty = false;
          if (item.siteOfCare_rd === 4 && item.siteOfCare_other === "") {
            siteOfCare_other_empty = true;
          }

          if (siteOfCare_rd_empty || siteOfCare_other_empty) {
            isValid = false;
          }

          return {
            ...item,
            siteOfCare_other_unk_soft: false,
            siteOfCare_rd_empty,
            siteOfCare_other_empty,
          };
        });
        setDoseOfTocilizumabDetails(updatedArray);
      }
    }

    if (
      epcoritamabDetails.q38_step_up_txt > 0 &&
      epcoritamabDetails.q38_step_up_txt != 1 &&
      epcoritamabDetails.q38step_up === 1
    ) {
      if (epcostepupdoseDetails && epcostepupdoseDetails.length > 0) {
        const updatedArray = epcostepupdoseDetails.map((item) => {
          let stepupdosedaysdifferenceRdEmpty = false;
          if (item.stepupdosedaysdifferenceRd == null) {
            stepupdosedaysdifferenceRdEmpty = true;
          }

          let stepupdosedaysdifferencetxtEmpty = false;
          if (
            item.stepupdosedaysdifferenceRd === 1 &&
            item.stepupdosedaysdifferenceTxt === ""
          ) {
            stepupdosedaysdifferencetxtEmpty = true;
          }

          let stepupdosedaysdifferenceTxtRange = false;
          if (
            item.stepupdosedaysdifferenceRd === 1 &&
            item.stepupdosedaysdifferenceTxt !== "" &&
            !ValueInRange(parseInt(item.stepupdosedaysdifferenceTxt), 1, 30)
          ) {
            stepupdosedaysdifferenceTxtRange = true;
          }

          if (
            stepupdosedaysdifferenceRdEmpty ||
            stepupdosedaysdifferencetxtEmpty ||
            stepupdosedaysdifferenceTxtRange
          ) {
            isValid = false;
          }

          return {
            ...item,

            stepupdosedaysdifferenceRdEmpty,
            stepupdosedaysdifferencetxtEmpty,
            stepupdosedaysdifferenceTxtRange,
          };
        });
        setEpcostepupdoseDetails(updatedArray);
      }
    }

    //New change Q41 stepup dose assessment repeater

    if (
      epcoritamabDetails.q38_step_up_txt > 0 &&
      epcoritamabDetails.q38step_up === 1
    ) {
      if (stepupdoseAssessment && stepupdoseAssessment.length > 0) {
        const updatedArray = stepupdoseAssessment.map((item) => {
          let siteofcare_doseRdEmpty = false;
          if (item.siteofcare_doseRd == null) {
            siteofcare_doseRdEmpty = true;
          }

          if (siteofcare_doseRdEmpty) {
            isValid = false;
          }

          return {
            ...item,

            siteofcare_doseRdEmpty,
          };
        });
        setStepupdoseAssessment(updatedArray);
      }
    }

    //Q51
    let epco_parent_empty = false;
    let epco_parent_othertext_empty = false;
    debugger;
    if (
      !epcoritamabDetails.medicationNone &&
      !epcoritamabDetails.medicationUnk &&
      medicationTypes?.every((a) => !a.isSelected)
    ) {
      epco_parent_empty = true;
    }
    if (!epco_parent_empty) {
      const medTypeOth = medicationTypes?.find(
        (a) => a.medicationTypeID === MedicationType.Other && a.isSelected
      );
      if (medTypeOth && isStringEmpty(medTypeOth.medicationTypeOther)) {
        epco_parent_othertext_empty = true;
      }
    }
    if (epco_parent_empty || epco_parent_othertext_empty) {
      isValid = false;
    }
    setHardErrList((prev) => ({
      ...prev,
      epco_parent_empty,
      epco_parent_othertext_empty,
    }));
    //52 to 55 rpt n 51 sub n subsub val.
    if (medicationTypes && medicationTypes.length > 0) {
      const updatedMedicationTypes = medicationTypes.map((medicationType) => {
        let epco_sub_empty = false;
        let epco_sub_oth_txt_empty = false;
        let epco_sub_sub_empty = false;
        if (
          medicationType.subMedicationTypes &&
          medicationType.subMedicationTypes.length > 0
        ) {
          epco_sub_empty =
            medicationType.isSelected &&
            medicationType.subMedicationTypes?.length > 0 &&
            medicationType.subMedicationTypes.every((a) => !a.isSelected);
        }
        if (!epco_sub_empty) {
          const subMedTypeOth = medicationType.subMedicationTypes?.find(
            (a) =>
              a.subMedicationTypeID === SupportiveCareEpcoritamab.Other &&
              a.isSelected
          );
          if (
            subMedTypeOth &&
            isStringEmpty(subMedTypeOth.subMedicationTypeOther)
          ) {
            epco_sub_oth_txt_empty = true;
          }
        }
        const updatedSubMedTypes = medicationType.subMedicationTypes.map(
          (subMedicationType) => {
            epco_sub_sub_empty =
              subMedicationType.isSelected &&
              subMedicationType.subSubMedicationTypes?.length > 0 &&
              subMedicationType.subSubMedicationTypes.every(
                (a) => !a.isSelected
              );

            const updatedSubSubMedTypes =
              subMedicationType.subSubMedicationTypes.map((subSubMedType) => {
                let updatedEpcorSupportiveCareDetails = [];

                if (
                  subSubMedType &&
                  subSubMedType.epcorsupportivecareDetails &&
                  subSubMedType.epcorsupportivecareDetails.length > 0
                ) {
                  updatedEpcorSupportiveCareDetails =
                    subSubMedType.epcorsupportivecareDetails.map((detail) => {
                      const supportive_careRdEmpty =
                        detail.supportive_careRd == null;
                      const supportive_care_dtEmpty =
                        detail.supportive_careRd === 1 &&
                        IsDateEmpty(detail.supportive_care_date);

                      const dosage_quantityRdEmpty =
                        detail.dosage_quantityRd == null;
                      const dosage_quantity_txtEmpty = dosage_quantityRdEmpty
                        ? false
                        : detail.dosage_quantityRd === 1 &&
                          isStringEmpty(detail.dosage_quantity_txt);

                      const dose_quantityRange =
                        detail.dosage_quantityRd === 1 &&
                        !isStringEmpty(String(detail.dosage_quantity_txt)) &&
                        !ValueInRange(
                          parseInt(detail.dosage_quantity_txt),
                          1,
                          999
                        );

                      let supportive_care_dtAfterIndex = false;
                      if (
                        IsDateValid(detail.supportive_care_date) &&
                        IsDateValid(index_date)
                      ) {
                        supportive_care_dtAfterIndex =
                          !IsDateEmpty(detail.supportive_care_date) &&
                          !IsDateEmpty(index_date) &&
                          !IsValidDate2(
                            detail.supportive_care_date,
                            index_date
                          );
                      }

                      let supportive_care_dtBefore_discnt_dt = false;
                      if (
                        IsDateValid(detail.supportive_care_date) &&
                        IsDateValid(epcoritamabDetails.dt_discont)
                      ) {
                        supportive_care_dtBefore_discnt_dt =
                          !IsDateEmpty(detail.supportive_care_date) &&
                          !IsDateEmpty(epcoritamabDetails.dt_discont) &&
                          !Is_DateBefore(
                            detail.supportive_care_date,
                            epcoritamabDetails.dt_discont
                          );
                      }

                      const dosefrequencyRdEmpty =
                        detail.dosefrequencyRd == null;
                      const dosefrequency_oth_txtEmpty = dosefrequencyRdEmpty
                        ? false
                        : detail.dosefrequencyRd === 4 &&
                          isStringEmpty(detail.dosefrequency_oth_txt);

                      const durationprescribedRdEmpty =
                        detail.durationprescribedRd == null;
                      const durationprescribed_txtEmpty =
                        durationprescribedRdEmpty
                          ? false
                          : detail.durationprescribedRd === 1 &&
                            isStringEmpty(
                              String(detail.durationprescribed_txt)
                            );

                      // Set `isValid` to false if any of these conditions are true
                      if (
                        supportive_careRdEmpty ||
                        supportive_care_dtEmpty ||
                        dosage_quantityRdEmpty ||
                        dosage_quantity_txtEmpty ||
                        dosefrequencyRdEmpty ||
                        dosefrequency_oth_txtEmpty ||
                        durationprescribedRdEmpty ||
                        durationprescribed_txtEmpty ||
                        dose_quantityRange ||
                        supportive_care_dtAfterIndex ||
                        supportive_care_dtBefore_discnt_dt
                      ) {
                        isValid = false;
                      }

                      return {
                        ...detail,
                        supportive_careRdEmpty,
                        supportive_care_dtEmpty,
                        dosage_quantityRdEmpty,
                        dosage_quantity_txtEmpty,
                        dosefrequencyRdEmpty,
                        dosefrequency_oth_txtEmpty,
                        durationprescribedRdEmpty,
                        durationprescribed_txtEmpty,
                        dose_quantityRange,
                        supportive_care_dtAfterIndex,
                        supportive_care_dtBefore_discnt_dt,
                        supportive_careUnkselect: false,
                        dose_quantity_Unkselect: false,
                        dosefrequency_Unkselect: false,
                        durationprescribedUnkselect: false,
                      };
                    });
                }

                return {
                  ...subSubMedType,
                  epcorsupportivecareDetails: updatedEpcorSupportiveCareDetails,
                };
              });
            if (epco_sub_sub_empty) {
              isValid = false;
            }
            return {
              ...subMedicationType,
              epco_sub_sub_empty,
              subSubMedicationTypes: updatedSubSubMedTypes,
            };
          }
        );
        if (epco_sub_empty || epco_sub_oth_txt_empty) {
          isValid = false;
        }
        return {
          ...medicationType,
          epco_sub_empty,
          epco_sub_oth_txt_empty,
          subMedicationTypes: updatedSubMedTypes,
        };
      });

      setMedicationTypes(updatedMedicationTypes);
    }



    
    if (
      (epcoritamabDetails.q40Discont === 1 || epcoritamabDetails.q40Discont === 99) &&
      epcoritamabDetails.receivedAdditionaltmt === null 
    ) {
      setHardErrList((prev) => ({
        ...prev,
        receivedAdditionaltmtEmpty: true,
      }));
  
      isValid = false;
    }

    return isValid;
  };

  const HandleFieldChange = (e) => {
    const { name, id, value, checked } = e.target;
debugger
    switch (name) {
      case "q36_state": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          // q36_other_txt:""
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          // q37EmptyagentTxt: false,
          // q37EmptyOtherTxt:false,
        }));
        break;
      }

      case "selectedLOT": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q36_state: 1,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q36Empty: false,
        }));

        //ST: 25/10/24: selectedLOTCheck is used to check if epcor_lot column in table has value or not
        //Only show popup when Q77 is present and visible in treatment after page
        if (
          Number(value) !== epcoritamabDetails.selectedLOT &&
          epcoritamabDetails.selectedLOT != null &&
          epcoritamabDetails.selectedLOTCheck != null
        ) {
          ShowAlertBox();
          setDropdownValueChanged(true);
        } else {
          setDropdownValueChanged(false);
        }
        break;
      }
      // case "q36_other_txt": {
      //   setepcoritamabDetails((prev) => ({
      //     ...prev,
      //     [name]: value,
      //     q36_state: 2,
      //   }));

      //   break;
      // }

      case "q37Mono": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q37Lena: false,
          q37Lonca: false,
          q37Vene: false,
          q37Ritux: false,
          q37InvestAgent: false,
          q37Other: false,
          q37InvestAgent_txt: "",
          q37Other_txt: "",
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;
      }

      case "q37Lena":
      case "q37Lonca":
      case "q37Vene":
      case "q37Ritux":
      case "q37InvestAgent":
      case "q37Other":
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "q37Other" && { q37Other_txt: "" }),
          ...(name === "q37InvestAgent" && { q37InvestAgent_txt: "" }),
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;

      case "q37InvestAgent_txt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q37InvestAgent: true,
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyagentTxt: false,
          q37EmptyOtherTxt: false,
        }));

        break;
      }

      case "q37Other_txt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q37Other: true,
          q37Mono: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q37Empty: false,
          q37EmptyOtherTxt: false,
          q37EmptyagentTxt: false,
        }));

        break;
      }

      case "q38step_up": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q38_step_up_txt: "",
          siteOfCare_FullDose: null,
        }));

        // if(Number(value) === 99){
        // }

        setSoftErrList((prev) => ({
          ...prev,
          q38Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q38Empty: false,
          SiteOfCareEmpty: false,
        }));

        if (Number(value) === 99) {
          setepcoritamabDetails((prev) => ({
            ...prev,
            q39step_up: null,
            q39_days_txt: "",
          }));

          setSoftErrList((prev) => ({
            ...prev,
            q39Unk_err: false,
          }));

          setHardErrList((prev) => ({
            ...prev,
            q39Empty: false,
          }));

          setEpcostepupdoseDetails([]);
          setStepupdoseAssessment([]);
        }

        break;
      }

      case "q38_step_up_txt": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }

        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value === "0" ? "" : value,
          q38step_up: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q38Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q38Empty: false,
        }));

        const maxRows = 9;
        const numberOfRows =
          Number(value) > maxRows ? maxRows : Number(value) - 1;

        if (!Number(value) || Number(value) === 1) {
          setEpcostepupdoseDetails([]); // Reset all rows if value is removed
        } else {
          // If value exists, merge previous data and add new rows
          setEpcostepupdoseDetails((prev) => {
            // Copy previous rows up to the number specified
            const existingRows = prev.slice(0, numberOfRows);

            // Create new rows if needed
            const newRows = Array.from(
              { length: numberOfRows - existingRows.length },
              (_, i) => ({
                ...EpcostepupdoseDetailsRow,
                id: existingRows.length + i + 1, // Generate a unique ID for new rows
              })
            );

            return [...existingRows, ...newRows]; // Merge existing and new rows
          });
        }

        const numofRowsSiteCare = Number(value) > 10 ? 10 : Number(value);

        if (!Number(value) || Number(value) === 0) {
          setStepupdoseAssessment([]); // Reset all rows if value is removed
        } else {
          // If value exists, merge previous data and add new rows
          setStepupdoseAssessment((prev) => {
            // Copy previous rows up to the number specified
            const existingRows = prev.slice(0, numofRowsSiteCare);

            // Create new rows if needed
            const newRows = Array.from(
              { length: numofRowsSiteCare - existingRows.length },
              (_, i) => ({
                ...stepupdoseAssessmentRow,
                id: existingRows.length + i + 1, // Generate a unique ID for new rows
              })
            );

            return [...existingRows, ...newRows]; // Merge existing and new rows
          });
        }

        break;
      }

      case "q39step_up": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q39_days_txt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q39Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q39Empty: false,
          q39SizeOutOfRange: false,
        }));

        break;
      }

      case "q39_days_txt": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q39step_up: 1,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q39Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q39Empty: false,
          q39SizeOutOfRange: false,
        }));

        break;
      }

      case "siteOfCare_Dose1": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "siteOfCare_Dose2": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "siteOfCare_FullDose": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          SiteOfCareEmpty: false,
        }));

        break;
      }

      case "q40Discont": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          q42DiscontReason: null,
          q41EpDiscontDtUnk: null,
          dt_discont: DefaultDate,
          //  q42DiscontReasonOtherTxt:"",

          epcoDiscontLbcl: false,
          epcoDiscontEligibility: false,
          epcoDiscontTransplant: false,
          epcoDiscontCompleteResponse: false,
          epcoDiscontInadequateResponse: false,
          epcoDiscontCovid19Infection: false,
          epcoDiscontCartTherapy: false,
          epcoDiscontPatientChoiceDecision: false,
          epcoDiscontOther: false,
          epcoDiscontUnknown: false,
          epcoDiscontReasonOtherTxt: "",
          receivedAdditionaltmt: null
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q40Unk_err: Number(value) === 99,
          q41Unk_err: false,
          q42Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q40Empty: false,
          q41Empty: false,
          q42Empty: false,
          q42txtEmpty: false,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));
        break;
      }

      // case "dt_discont":{

      // }

      case "q41EpDiscontDtUnk": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          dt_discont: DefaultDate, //reset q8 date
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q41Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          q41_date_afterIndex: false,
          q41_date_beforeDeathorChart: false,
        }));
        break;
      }

      //q42

      case "q42DiscontReason": {
        const value_int = Number(value);
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value_int,
          q42DiscontReasonOtherTxt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q42Unk_err: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q42Empty: false,
          q42txtEmpty: false,
        }));

        // if(value_int === 99)
        // setIsEligible(false);
        break;
      }
      case "q42DiscontReasonOtherTxt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          q42DiscontReason: 10,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          q42Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q42Empty: false,
          q42txtEmpty: false,
        }));

        break;
      }

      case "dosesOfTocilizumabNumOfDoses": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);

        setHardErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_doses_of_tocilizumab_unknown: Number(value) === 99,
        }));

        break;
      }

      case "epcoDiscontLbcl":
      case "epcoDiscontEligibility":
      case "epcoDiscontTransplant":
      case "epcoDiscontCompleteResponse":
      case "epcoDiscontInadequateResponse":
      case "epcoDiscontCovid19Infection":
      case "epcoDiscontCartTherapy":
      case "epcoDiscontPatientChoiceDecision":
      case "epcoDiscontOther":
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "epcoDiscontOther" && { epcoDiscontReasonOtherTxt: "" }),
          epcoDiscontUnknown: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        break;

      case "epcoDiscontReasonOtherTxt": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value,
          epcoDiscontOther: true,
          epcoDiscontUnknown: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        break;
      }

      case "epcoDiscontUnknown": {
        // const value_int = Number(value);
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          //   epcoDiscontReasonOtherTxt: "",
          epcoDiscontLbcl: false,
          epcoDiscontEligibility: false,
          epcoDiscontTransplant: false,
          epcoDiscontCompleteResponse: false,
          epcoDiscontInadequateResponse: false,
          epcoDiscontCovid19Infection: false,
          epcoDiscontCartTherapy: false,
          epcoDiscontPatientChoiceDecision: false,
          epcoDiscontOther: false,
          epcoDiscontReasonOtherTxt: "",
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_discont_unk_err: true,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_discont_reasonEmpty: false,
          epco_discont_reasonEmpty_txtEmpty: false,
        }));

        // if(value_int === 99)
        // setIsEligible(false);
        break;
      }

      case "medicationUnk": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: true,
          medicationNone: false,
        }));

        const updatedArray = medicationTypes.map((medType) => ({
          ...medType,
          isSelected: false,
          medicationTypeOther: "",
          subMedicationTypes: medType.subMedicationTypes.map((subMed) => ({
            ...subMed,
            isSelected: false, // Reset isSelected for subMedicationType
            subMedicationTypeOther: "",
            subSubMedicationTypes: subMed.subSubMedicationTypes.map(
              (subSubMed) => ({
                ...subSubMed,
                isSelected: false, // Reset isSelected for subSubMedicationType
              })
            ),
          })),
        }));
        setMedicationTypes(updatedArray);

        setTocilizumabChecked(false);

        setepcoritamabDetails((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses: null,
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: true,
        }));
        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
        }));

        setTocSelected(false);

        setSoftErrList((prev) => ({
          ...prev,
          epco_doses_of_tocilizumab_unknown: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_Empty: false,
        }));
        break;
      }

      case "medicationNone": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: true,
          medicationUnk: false,
        }));

        const updatedArray = medicationTypes.map((medType) => ({
          ...medType,
          isSelected: false,
          medicationTypeOther: "",
          subMedicationTypes: medType.subMedicationTypes.map((subMed) => ({
            ...subMed,
            isSelected: false, // Reset isSelected for subMedicationType
            subMedicationTypeOther: "",
            subSubMedicationTypes: subMed.subSubMedicationTypes.map(
              (subSubMed) => ({
                ...subSubMed,
                isSelected: false, // Reset isSelected for subSubMedicationType
              })
            ),
          })),
        }));
        setMedicationTypes(updatedArray);

        setTocilizumabChecked(false);

        setepcoritamabDetails((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses: null,
        }));

        setDoseOfTocilizumabDetails([
          {
            ...DoseOfTocilizumabRow,
          },
        ]);

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
        }));

        setTocSelected(false);

        setSoftErrList((prev) => ({
          ...prev,
          epco_doses_of_tocilizumab_unknown: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          dosesOfTocilizumabNumOfDoses_Empty: false,
        }));
        break;
      }

      case "receivedAdditionaltmt": {
        const value_int = Number(value);
        setepcoritamabDetails((prev) => ({
          ...prev,
          [name]: value_int,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          receivedAdditionaltmtSoft: Number(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          receivedAdditionaltmtEmpty: false,

        }));

        // if(value_int === 99)
        // setIsEligible(false);
        break;
      }

      default:
        break;
    }
    // Hide top error message after an option is selected
    setShowTopErrMsg(false);
  };

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      let isValid = validate ? ValidateForm() : true;

      // if (!ValidateForm()) {
      //     setShowTopErrMsg(true);
      //     ToggleLoader(false);
      //     return false;
      // }
      // setShowTopErrMsg(false);

      if (
        !IsDateEmpty(epcoritamabDetails.dt_discont) &&
        !IsDateValid(epcoritamabDetails.dt_discont)
      ) {
        isValid = false;
      }

      if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
        epcorAssessmentsDetails.map((item) => {
          if (
            !IsDateEmpty(item.radiological_assess_dx_date) &&
            !IsDateValid(item.radiological_assess_dx_date)
          ) {
            isValid = false;
          }
          return item;
        });
      }

      //validate date of epcorsupportivecareDetails rpt
      if (isValid) {
        if (medicationTypes && medicationTypes.length > 0) {
          const glucoObj = medicationTypes.find(
            (a) =>
              a.medicationTypeID === MedicationType.Glucocorticoids &&
              a.isSelected
          );

          if (glucoObj) {
            const subArr = glucoObj.subMedicationTypes;

            if (subArr && subArr.length > 0) {
              // Iterate over subMedicationTypes
              for (let sub of subArr) {
                if (!isValid) break;

                // Iterate over subSubMedicationTypes
                for (let ss of sub.subSubMedicationTypes) {
                  if (!isValid) break;

                  // Iterate over epcorsupportivecareDetails
                  for (let itm of ss.epcorsupportivecareDetails) {
                    if (!isValid) break;

                    if (
                      !IsDateEmpty(itm.supportive_care_date) &&
                      !IsDateValid(itm.supportive_care_date)
                    ) {
                      isValid = false;
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
      //end

      if (isValid) {
        // const PatientMedicationData = {
        //   MedicationTypes: medicationTypes
        // }

        let updatedMedicationTypes = [];
        if (medicationTypes && medicationTypes.length > 0) {
          updatedMedicationTypes = medicationTypes.map((medicationType) => {
            const updatedSubMedTypes = medicationType.subMedicationTypes.map(
              (subMedicationType) => {
                const updatedSubSubMedTypes =
                  subMedicationType.subSubMedicationTypes.map(
                    (subSubMedType) => {
                      let updatedEpcorSupportiveCareDetails = [];

                      if (
                        subSubMedType &&
                        subSubMedType.epcorsupportivecareDetails &&
                        subSubMedType.epcorsupportivecareDetails.length > 0
                      ) {
                        updatedEpcorSupportiveCareDetails =
                          subSubMedType.epcorsupportivecareDetails.map(
                            (detail) => {
                              return {
                                EpcoritamabSupportiveCareBridgeId:
                                  detail.epcoritamabsupportivecarebridgeid,
                                EpcoritamabTreatmentMedicationTypeId:
                                  detail.epcoritamabtreatmentMedicationtypeid,
                                DoseReceivedDate:
                                  detail.supportive_careRd === 1 &&
                                  !IsDateEmpty(detail.supportive_care_date)
                                    ? ParseDate(detail.supportive_care_date)
                                    : null,
                                DoseReceivedDateDmuid:
                                  detail.supportive_careRd === 1
                                    ? GetDMUID(detail.supportive_care_date)
                                    : null,
                                DoseReceivedDateUnk:
                                  detail.supportive_careRd === 99,
                                DoseQuantity: detail.dosage_quantityRd,
                                DoseQuantityTxt: detail.dosage_quantity_txt,
                                DoseFrequency: detail.dosefrequencyRd,
                                DoseFrequencyOthTxt:
                                  detail.dosefrequency_oth_txt,
                                DoseDurationPrescribed:
                                  detail.durationprescribedRd,
                                DoseDurationPrescribedDays:
                                  detail.durationprescribed_txt,
                              };
                            }
                          );
                      }

                      return {
                        ...subSubMedType,
                        epcorsupportivecareDetails:
                          updatedEpcorSupportiveCareDetails,
                      };
                    }
                  );

                return {
                  ...subMedicationType,
                  subSubMedicationTypes: updatedSubSubMedTypes,
                };
              }
            );

            return {
              ...medicationType,
              subMedicationTypes: updatedSubMedTypes,
            };
          });
        }

        // Send formattedPayload to your API or handle accordingly
        console.log("Payload to save:", updatedMedicationTypes);
        const PatientMedicationData = {
          MedicationTypes: updatedMedicationTypes,
        };

        setShowTopErrMsg(false);
        const epcoritamabModel = {
          // SaveMedication: formattedPayload,
          PatientMedicationData, //Q51
          patientId: pid,

          //question 36
          epcorLot:
            epcoritamabDetails.q36_state === 1
              ? epcoritamabDetails.selectedLOT
              : null,

          DropdownValueChanged: dropdownValueChanged ? true : false,

          //question 37 payload
          receivedMonotherapyTreatment: epcoritamabDetails.q37Mono,
          otherTreatmentLenalidomide: epcoritamabDetails.q37Lena,
          otherTreatmentLoncastuximab: epcoritamabDetails.q37Lonca,
          otherTreatmentRituximab: epcoritamabDetails.q37Ritux,
          otherTreatmentVenetoclax: epcoritamabDetails.q37Vene,
          OtherTreatmentInvestigationalagent: epcoritamabDetails.q37InvestAgent,
          otherTreatmentInvestigationalagentTxt:
            epcoritamabDetails.q37InvestAgent_txt,
          OtherTreatmentOth: epcoritamabDetails.q37Other,
          OtherTreatmentOthTxt: epcoritamabDetails.q37Other_txt,

          //question 38 payload
          StepupdosesReceived: epcoritamabDetails.q38_step_up_txt,
          StepupdosesReceivedUnk:
            epcoritamabDetails.q38step_up === 99 ? true : false,

          //question 39 payload
          dose2and3DaysDifference: epcoritamabDetails.q39_days_txt,
          dose2and3DaysDifferenceUnk:
            epcoritamabDetails.q39step_up === 99 ? true : false,

          //question 40
          discontinueEpcoritamab: epcoritamabDetails.q40Discont,

          //Q40. Site Of Care
          StepupDose1: epcoritamabDetails.siteOfCare_Dose1,
          StepupDose2: epcoritamabDetails.siteOfCare_Dose2,
          FirstFullDose: epcoritamabDetails.siteOfCare_FullDose,

          //43 New question changes payload

          EpcoDiscontLbcl: epcoritamabDetails.epcoDiscontLbcl,
          EpcoDiscontEligibility: epcoritamabDetails.epcoDiscontEligibility,
          EpcoDiscontTransplant: epcoritamabDetails.epcoDiscontTransplant,
          EpcoDiscontCompleteResponse:
            epcoritamabDetails.epcoDiscontCompleteResponse,
          EpcoDiscontInadequateResponse:
            epcoritamabDetails.epcoDiscontInadequateResponse,
          EpcoDiscontCovid19Infection:
            epcoritamabDetails.epcoDiscontCovid19Infection,
          EpcoDiscontCartTherapy: epcoritamabDetails.epcoDiscontCartTherapy,
          EpcoDiscontPatientChoiceDecision:
            epcoritamabDetails.epcoDiscontPatientChoiceDecision,
          EpcoDiscontOther: epcoritamabDetails.epcoDiscontOther,
          EpcoDiscontUnknown: epcoritamabDetails.epcoDiscontUnknown,
          EpcoritamabDiscontReasonOthTxt:
            epcoritamabDetails.epcoDiscontReasonOtherTxt,

          //50

          MedicationNone: epcoritamabDetails.medicationNone,
          MedicationUnk: epcoritamabDetails.medicationUnk,
          //51 new
          TocilizumabDosesReceived:
            epcoritamabDetails.dosesOfTocilizumabNumOfDoses,

            //59
            AdditionalTherapyReceived:epcoritamabDetails.receivedAdditionaltmt,

          //question 41
          epcoritamabDiscontDate:
            epcoritamabDetails.q40Discont === 1 &&
            !IsDateEmpty(epcoritamabDetails.dt_discont) &&
            epcoritamabDetails.q41EpDiscontDtUnk === 1
              ? ParseDate(epcoritamabDetails.dt_discont)
              : null,
          epcoritamabDiscontDateDmuid:
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q41EpDiscontDtUnk === 1
              ? GetDMUID(epcoritamabDetails.dt_discont)
              : null,
          epcoritamabDiscontDateUnk:
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q41EpDiscontDtUnk === 99
              ? epcoritamabDetails.q41EpDiscontDtUnk
              : false,

          //question 42

          // epcoritamabDiscontReason: epcoritamabDetails.q42DiscontReason,
          // epcoritamabDiscontReasonOthTxt:
          //     epcoritamabDetails.q42DiscontReason === 10
          //         ? epcoritamabDetails.q42DiscontReasonOtherTxt
          //         : null,

          EpcorDetailsList: [],
          EpcorsiteofcareDetails: [],
          EpcorstepupdoseDetails: [],
          EpcorstepupdoseAssessmentDetails: [],
          EpcorsupportivecareDetails: [],
          createdby: GetLoggedInUserID() ?? 0,
          editedby: GetLoggedInUserID() ?? 0,
          userId: GetLoggedInUserID(),
          isValidated: validate,
        };

        if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
          epcoritamabModel.EpcorDetailsList = epcorAssessmentsDetails.map(
            (a) => ({
              Epcoritamabradiologicalassessmentid:
                a.epcoritamabradiologicalassessmentid,
              RadiologicalAssessmentDate:
                a.radiological_assess_dx === 1 &&
                !IsDateEmpty(a.radiological_assess_dx_date)
                  ? ParseDate(a.radiological_assess_dx_date)
                  : null,
              RadiologicalAssessmentDateDmuid:
                a.radiological_assess_dx === 1
                  ? GetDMUID(a.radiological_assess_dx_date)
                  : null,
              RadiologicalAssessmentDateUnk: a.radiological_assess_dx === 99,

              LbclDiseaseCategory: a.q44_LBCL_status,
              LbclDiseaseCategoryOthTxt: a.q44_lbcl_oth_txt,

              LyricLuganoAssessment: a.q45_stage_status,
              LyricCriteria:
                a.q45_stage_status === 1 || a.q45_stage_status === 3
                  ? a.q46LyricAssess
                  : null,

              RadiologicalAssessmentType:
                // a.q45_stage_status === 2 || a.q45_stage_status === 4
                //   ?
                a.q47_radiology_type,
              // : null,
              RadiologicalAssessmentTypeOthTxt: a.q47RadioAssessTypeOtherTxt,
            })
          );
        }

        if (doseOfTocilizumabDetails && doseOfTocilizumabDetails.length > 0) {
          epcoritamabModel.EpcorsiteofcareDetails =
            doseOfTocilizumabDetails.map((a) => ({
              Epcoritamabtreatmentsitecarebridgeid:
                a.epcoritamabtreatmentsitecarebridgeid,

              EpcoritamabSiteofcare: a.siteOfCare_rd,
              EpcoritamabSiteofcareOthTxt: a.siteOfCare_other,
            }));
        }

        if (epcostepupdoseDetails && epcostepupdoseDetails.length > 0) {
          epcoritamabModel.EpcorstepupdoseDetails = epcostepupdoseDetails.map(
            (a) => ({
              Epcoritamabtreatmentstepupdosebridgeid:
                a.epcoritamabtreatmentstepupdosebridgeid,

              StepUpDoseDaysDifference: a.stepupdosedaysdifferenceTxt,
              StepUpDoseDaysDifferenceUnk:
                a.stepupdosedaysdifferenceRd === 99 ? true : false,
            })
          );
        }

        if (stepupdoseAssessment && stepupdoseAssessment.length > 0) {
          epcoritamabModel.EpcorstepupdoseAssessmentDetails =
            stepupdoseAssessment.map((a) => ({
              Epcoritamabstepupdoseassessmentbridgeid:
                a.epcoritamabstepupdoseassessmentbridgeid,
              StepupDose: a.siteofcare_doseRd,
            }));
        }

        // if (medicationData && medicationData.length > 0) {
        //   epcoritamabModel.EpcorsupportivecareDetails = medicationData.map(
        //     (a) => ({

        //       Epcoritamabsupportivecarebridgeid:
        //         a.epcoritamabsupportivecarebridgeid,

        //         EpcoritamabtreatmentMedicationtypeid:
        //         a.epcoritamabtreatmentMedicationtypeid,

        //       DoseReceivedDate:
        //         a.supportive_careRd === 1 &&
        //         !IsDateEmpty(a.supportive_care_date)
        //           ? ParseDate(a.supportive_care_date)
        //           : null,

        //           DoseReceivedDateDmuid:
        //         a.supportive_careRd === 1
        //           ? GetDMUID(a.supportive_care_date)
        //           : null,

        //           DoseQuantity: a.dosage_quantityRd,
        //           DoseQuantityTxt: a.dosage_quantity_txt,

        //           DoseFrequency: a.dosefrequencyRd,
        //           DoseFrequencyOthTxt: a.dosefrequency_oth_txt,

        //           DoseDurationPrescribed:a.durationprescribedRd,
        //           DoseDurationPrescribedDays:a.durationprescribed_txt,
        //     })
        //   );
        // }

        const medicationArrayKeys = Object.keys(medicationData);
        const hasMedicationData = medicationArrayKeys.some(
          (key) =>
            Array.isArray(medicationData[key]) && medicationData[key].length > 0
        );

        if (hasMedicationData) {
          epcoritamabModel.EpcorsupportivecareDetails =
            medicationArrayKeys.flatMap((key) =>
              medicationData[key].map((a) => ({
                Epcoritamabsupportivecarebridgeid:
                  a.epcoritamabsupportivecarebridgeid,
                EpcoritamabtreatmentMedicationtypeid:
                  a.epcoritamabtreatmentMedicationtypeid,
                DoseReceivedDate:
                  a.supportive_careRd === 1 &&
                  !IsDateEmpty(a.supportive_care_date)
                    ? ParseDate(a.supportive_care_date)
                    : null,
                DoseReceivedDateDmuid:
                  a.supportive_careRd === 1
                    ? GetDMUID(a.supportive_care_date)
                    : null,
                DoseQuantity: a.dosage_quantityRd,
                DoseQuantityTxt: a.dosage_quantity_txt,
                DoseFrequency: a.dosefrequencyRd,
                DoseFrequencyOthTxt: a.dosefrequency_oth_txt,
                DoseDurationPrescribed: a.durationprescribedRd,
                DoseDurationPrescribedDays: a.durationprescribed_txt,
              }))
            );
        }

        console.log("payload==", epcoritamabModel);
        if (pid > 0) {
          const response = await TreatmentResponseService.SaveTreatmentResponse(
            epcoritamabModel
          );
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            await LoadData();
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            //All lots removed
            if (epcoritamabDetails.selectedLOT > 1) {
              navigatePaths.nextPage = "/TreatmentsPriorEpcoritamab/1";
            } else {
              navigatePaths.nextPage = "/CARTCellTherapy";
            }
          }
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const LoadData = async () => {
    // debugger
    try {
      if (patientId > 0) {
        const response =
          await TreatmentResponseService.GetAllEpcoritamabResponse(patientId);

        console.log(
          "setMedicationTypes",
          response.data.patientMedicationData.medicationTypes
        );
        if (response?.status === HTTPResponse.OK) {
          const medicationTypes =
            response.data.patientMedicationData.medicationTypes;
          let updatedMedicationTypes = [];
          if (medicationTypes && medicationTypes.length > 0) {
            updatedMedicationTypes = medicationTypes.map((medicationType) => {
              const updatedSubMedTypes = medicationType.subMedicationTypes.map(
                (subMedicationType) => {
                  const updatedSubSubMedTypes =
                    subMedicationType.subSubMedicationTypes.map(
                      (subSubMedType) => {
                        let updatedEpcorSupportiveCareDetails = [];

                        if (
                          subSubMedType &&
                          subSubMedType.epcorsupportivecareDetails &&
                          subSubMedType.epcorsupportivecareDetails.length > 0
                        ) {
                          updatedEpcorSupportiveCareDetails =
                            subSubMedType.epcorsupportivecareDetails.map(
                              (detail) => {
                                return {
                                  ...initialGlucocorticoidsRow,
                                  epcoritamabsupportivecarebridgeid:
                                    detail.epcoritamabSupportiveCareBridgeId,
                                  epcoritamabtreatmentMedicationtypeid:
                                    detail.epcoritamabTreatmentMedicationTypeId,
                                  supportive_care_date: !isStringEmpty(
                                    detail.doseReceivedDate
                                  )
                                    ? GetDisplayDate(
                                        detail.doseReceivedDate,
                                        detail.doseReceivedDateDmuid
                                      )
                                    : DefaultDate,
                                  supportive_careRd: detail.doseReceivedDateUnk
                                    ? 99
                                    : !isStringEmpty(detail.doseReceivedDate)
                                    ? 1
                                    : null,
                                  dosage_quantityRd: detail.doseQuantity,
                                  dosage_quantity_txt: detail.doseQuantityTxt,
                                  dosefrequencyRd: detail.doseFrequency,
                                  dosefrequency_oth_txt:
                                    detail.doseFrequencyOthTxt,
                                  durationprescribedRd:
                                    detail.doseDurationPrescribed,
                                  durationprescribed_txt:
                                    detail.doseDurationPrescribedDays,
                                };
                              }
                            );
                        }

                        return {
                          ...subSubMedType,
                          epcorsupportivecareDetails:
                            updatedEpcorSupportiveCareDetails,
                        };
                      }
                    );

                  return {
                    ...subMedicationType,
                    subSubMedicationTypes: updatedSubSubMedTypes,
                    epco_sub_sub_empty: false, //hard err
                  };
                }
              );

              return {
                ...medicationType,
                epco_sub_empty: false, //hard err
                epco_sub_oth_txt_empty: false, //hard err
                subMedicationTypes: updatedSubMedTypes,
              };
            });
          }
          // if(medicationTypes && medicationTypes.length >0){
          //   medicationTypes.forEach(a => a.subMedicationTypes.forEach(b => b.subSubMedicationTypes.forEach(c =>
          //     c.epcorsupportivecareDetails.forEach(d => {
          //       d.supportive_careRdEmpty= false;
          //       d.supportive_care_dtEmpty= false;
          //       d.dosage_quantityRdEmpty= false;
          //       d.dosage_quantity_txtEmpty= false;
          //       d.dosefrequencyRdEmpty= false;
          //       d.dosefrequency_oth_txtEmpty= false;
          //       d.durationprescribedRdEmpty= false;
          //       d.durationprescribed_txtEmpty= false;
          //     }))))
          // }
          setMedicationTypes(updatedMedicationTypes);

          const tocilizumabItem =
            response.data.patientMedicationData.medicationTypes.find(
              (item) => item.medicationTypeID === 6
            );
          // Check if isSelected is true or false and log it
          if (tocilizumabItem)
            setTocilizumabChecked(tocilizumabItem.isSelected);

          // initialSelections(
          //   response.data.patientMedicationData.patientTreatment,
          //   // response.data.patientMedicationData.treatmentMedicationBridges
          // );

          const { data } = response;
          setepcoritamabDetails((prev) => ({
            ...prev,
            userId: data.userId,

            selectedLOT: data.epcorLot,
            selectedLOTCheck: data.epcorLot,
            q36_state: data.epcorLot !== null ? 1 : null,

            q37Mono: data.receivedMonotherapyTreatment === true ? 1 : false,
            q37Lena: data.otherTreatmentLenalidomide,
            q37Lonca: data.otherTreatmentLoncastuximab,
            q37Ritux: data.otherTreatmentRituximab,
            q37Vene: data.otherTreatmentVenetoclax,
            q37InvestAgent: data.otherTreatmentInvestigationalagent,
            q37InvestAgent_txt: data.otherTreatmentInvestigationalagentTxt,
            q37Other: data.otherTreatmentOth,
            q37Other_txt: data.otherTreatmentOthTxt,

            q38step_up:
              data.stepupdosesReceived !== null
                ? 1
                : data.stepupdosesReceivedUnk === true
                ? 99
                : null,
            q38_step_up_txt: data.stepupdosesReceived ?? null,

            //  q39step_up: data.dose2and3DaysDifference !== null ? 1 : null,
            //  q39step_up: data.dose2and3DaysDifferenceUnk === true ? 99 : 1,

            q39step_up:
              data.dose2and3DaysDifference !== null
                ? 1
                : data.dose2and3DaysDifferenceUnk === true
                ? 99
                : null,
            q39_days_txt: data.dose2and3DaysDifference ?? "",

            q40Discont:
              data.discontinueEpcoritamab != null
                ? data.discontinueEpcoritamab
                : null,

            dt_discont: !isStringEmpty(data.epcoritamabDiscontDate)
              ? GetDisplayDate(
                  data.epcoritamabDiscontDate,
                  data.epcoritamabDiscontDateDmuid
                )
              : DefaultDate,

            //SiteOfCare Q40. new
            siteOfCare_Dose1: data.stepupDose1,
            siteOfCare_Dose2: data.stepupDose2,
            siteOfCare_FullDose: data.firstFullDose,

            //loadData 43 new

            epcoDiscontLbcl: data.epcoDiscontLbcl,
            epcoDiscontEligibility: data.epcoDiscontEligibility,
            epcoDiscontTransplant: data.epcoDiscontTransplant,
            epcoDiscontCompleteResponse: data.epcoDiscontCompleteResponse,
            epcoDiscontInadequateResponse: data.epcoDiscontInadequateResponse,
            epcoDiscontCovid19Infection: data.epcoDiscontCovid19Infection,
            epcoDiscontCartTherapy: data.epcoDiscontCartTherapy,
            epcoDiscontPatientChoiceDecision:
              data.epcoDiscontPatientChoiceDecision,
            epcoDiscontOther: data.epcoDiscontOther,
            epcoDiscontUnknown: data.epcoDiscontUnknown,
            epcoDiscontReasonOtherTxt: data.epcoritamabDiscontReasonOthTxt,

            medicationNone: data.medicationNone,
            medicationUnk: data.medicationUnk,
            //51 new
            dosesOfTocilizumabNumOfDoses: data.tocilizumabDosesReceived,

            //59 
            receivedAdditionaltmt:data.additionalTherapyReceived,

            q41EpDiscontDtUnk: data.epcoritamabDiscontDateUnk
              ? 99
              : data.epcoritamabDiscontDate != null
              ? 1
              : null,

            q42DiscontReason: data.epcoritamabDiscontReasonOthTxt
              ? 10
              : data.epcoritamabDiscontReason,
            q42DiscontReasonOtherTxt: data.epcoritamabDiscontReasonOthTxt ?? "",
          }));

          //Repeater load data

          if (
            data.epcorDetailsList != null &&
            data.epcorDetailsList.length > 0
          ) {
            const { epcorDetailsList } = data;
            const epcorDetailsArray = epcorDetailsList.map((item, index) => {
              const epcoritamabradiologicalassessmentid =
                item.epcoritamabradiologicalassessmentid != null
                  ? item.epcoritamabradiologicalassessmentid
                  : 0;
              const radiological_assess_dx_date = !isStringEmpty(
                item.radiologicalAssessmentDate
              )
                ? GetDisplayDate(
                    item.radiologicalAssessmentDate,
                    item.radiologicalAssessmentDateDmuid
                  )
                : DefaultDate;

              // Determine initial visibility for Q46 and Q47 based on q45_stage_status value
              if (
                item.lyricLuganoAssessment === 1 ||
                item.lyricLuganoAssessment === 3
              ) {
                setShowQ46((prev) => ({ ...prev, [index]: true }));
                //   setShowQ47((prev) => ({ ...prev, [index]: false }));
              } else if (
                item.lyricLuganoAssessment === 2 ||
                item.lyricLuganoAssessment === 4
              ) {
                setShowQ46((prev) => ({ ...prev, [index]: false }));
                //  setShowQ47((prev) => ({ ...prev, [index]: true }));
              }
              return {
                ...EPCORAssesmentRow,
                epcoritamabradiologicalassessmentid,
                radiological_assess_dx: item.radiologicalAssessmentDateUnk
                  ? 99
                  : !isStringEmpty(item.radiologicalAssessmentDate)
                  ? 1
                  : null,
                radiological_assess_dx_date,

                q44_LBCL_status: item.lbclDiseaseCategory,
                q44_lbcl_oth_txt: item.lbclDiseaseCategoryOthTxt,

                q45_stage_status: item.lyricLuganoAssessment,

                q46LyricAssess: item.lyricCriteria,

                q47_radiology_type: item.radiologicalAssessmentType,
                q47RadioAssessTypeOtherTxt:
                  item.radiologicalAssessmentTypeOthTxt,
              };
            });
            setEPCORAssessmentsDetails(epcorDetailsArray);
          }

          if (
            data.epcorsiteofcareDetails != null &&
            data.epcorsiteofcareDetails.length > 0
          ) {
            const { epcorsiteofcareDetails } = data;
            const epcorDetailsListArray = epcorsiteofcareDetails.map(
              (item, index) => {
                const epcoritamabtreatmentsitecarebridgeid =
                  item.epcoritamabtreatmentsitecarebridgeid != null
                    ? item.epcoritamabtreatmentsitecarebridgeid
                    : 0;

                return {
                  ...DoseOfTocilizumabRow,
                  epcoritamabtreatmentsitecarebridgeid,

                  siteOfCare_rd: item.epcoritamabSiteofcare,
                  siteOfCare_other: item.epcoritamabSiteofcareOthTxt,
                };
              }
            );
            setDoseOfTocilizumabDetails(epcorDetailsListArray);
          }

          if (
            data.epcorstepupdoseDetails != null &&
            data.epcorstepupdoseDetails.length > 0
          ) {
            const { epcorstepupdoseDetails } = data;
            const epcorstepupdoseDetailsListArray = epcorstepupdoseDetails.map(
              (item, index) => {
                const epcoritamabtreatmentstepupdosebridgeid =
                  item.epcoritamabtreatmentstepupdosebridgeid != null
                    ? item.epcoritamabtreatmentstepupdosebridgeid
                    : 0;

                return {
                  ...EpcostepupdoseDetailsRow,
                  epcoritamabtreatmentstepupdosebridgeid,

                  stepupdosedaysdifferenceRd:
                    item.stepUpDoseDaysDifferenceUnk === true
                      ? 99
                      : item.stepUpDoseDaysDifference !== null
                      ? 1
                      : null,
                  stepupdosedaysdifferenceTxt: item.stepUpDoseDaysDifference,
                };
              }
            );
            setEpcostepupdoseDetails(epcorstepupdoseDetailsListArray);
          }

          if (
            data.epcorstepupdoseAssessmentDetails != null &&
            data.epcorstepupdoseAssessmentDetails.length > 0
          ) {
            const { epcorstepupdoseAssessmentDetails } = data;
            const epcorstepupdoseAssessDetailsListArray =
              epcorstepupdoseAssessmentDetails.map((item, index) => {
                const epcoritamabstepupdoseassessmentbridgeid =
                  item.epcoritamabstepupdoseassessmentbridgeid != null
                    ? item.epcoritamabstepupdoseassessmentbridgeid
                    : 0;

                return {
                  ...stepupdoseAssessmentRow,
                  epcoritamabstepupdoseassessmentbridgeid,
                  siteofcare_doseRd: item.stepupDose,
                };
              });
            setStepupdoseAssessment(epcorstepupdoseAssessDetailsListArray);
          }

          if (data.indexDate) {
            setIndexDate(data.indexDate);
          }

          if (data.chartAbstractDate) {
            setChartAbstractionDate(data.chartAbstractDate);
          }

          if (data.deathDate) {
            set_Death_Date(data.deathDate);
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  const HandleDateChange = (name, date, datePart) => {
    switch (name) {
      case "dt_discont": {
        setepcoritamabDetails((prev) => ({
          ...prev,
          q41EpDiscontDtUnk: 1,
          dt_discont: SetDateState(prev.dt_discont, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q41Unk_err: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q41Empty: false,
          // q41_date_invalid1:false,
          q41_date_afterIndex: false,
          q41_date_beforeDeathorChart: false,
        }));
        break;
      }

      default:
        break;
    }
  };

  const ShowAlertBox = async () => {
    const choice = await ShowActionPopUp(
      "Alert", //title
      CommonError_Msgs.Dropdownchange, //message
      "Ok", //Confirm button text
      true
    );
  };

  const EpcostepupdoseDetailsRow = {
    epcoritamabtreatmentstepupdosebridgeid: 0, // primary key
    stepupdosedaysdifferenceRd: null,
    stepupdosedaysdifferenceTxt: "",

    stepupdosedaysdifferenceRdEmpty: false,
    stepupdosedaysdifferencetxtEmpty: false,
    stepupdosedaysdifferenceUnkSoft: false,
    stepupdosedaysdifferenceTxtRange: false,
  };

  const [epcostepupdoseDetails, setEpcostepupdoseDetails] = useState([
    EpcostepupdoseDetailsRow,
  ]);

  const stepupdoseAssessmentRow = {
    epcoritamabstepupdoseassessmentbridgeid: 0, // primary key
    siteofcare_doseRd: null,
    siteofcare_doseRdEmpty: false,
  };

  const [stepupdoseAssessment, setStepupdoseAssessment] = useState([
    stepupdoseAssessmentRow,
  ]);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);

  const handleTooltipToggle = (id) => {
    setIsTooltipClicked((prev) => !prev);
    setIsTooltipVisible((prev) => (prev === id ? null : id));
  };

  const handleMouseEnter = (id) => {
    if (!isTooltipClicked && isTooltipVisible !== id) {
      setIsTooltipVisible(id);
    }
  };

  const handleMouseLeave = (id) => {
    // Keep the tooltip visible if it was clicked
    if (!isTooltipClicked && isTooltipVisible === id) {
      setIsTooltipVisible(null);
    }
  };

  //const [selectedLOT, setSelectedLOT] = useState(null);

  const initialGlucocorticoidsRow = {
    epcoritamabsupportivecarebridgeid: 0,
    epcoritamabtreatmentMedicationtypeid: 0,
    medtypeid: 0,
    submedtyeid: 0,
    subsubmedtypeid: 0,
    name: "",
    supportive_careRd: null,
    supportive_care_date: DefaultDate,
    supportive_careRdEmpty: false,
    supportive_care_dtEmpty: false,
    supportive_careUnkselect: false,
    supportive_care_dtAfterIndex: false,
    supportive_care_dtBefore_discnt_dt: false,

    dosage_quantityRd: null,
    dosage_quantity_txt: "",
    dosage_quantityRdEmpty: false,
    dosage_quantity_txtEmpty: false,
    dose_quantity_Unkselect: false,
    dose_quantityRange: false,
    dosefrequencyRd: null,
    dosefrequency_oth_txt: "",
    dosefrequencyRdEmpty: false,
    dosefrequency_oth_txtEmpty: false,
    dosefrequency_Unkselect: false,
    durationprescribedRd: null,
    durationprescribed_txt: "",
    durationprescribedRdEmpty: false,
    durationprescribed_txtEmpty: false,
    durationprescribedUnkselect: false,
    
  };
  const [medicationData, setMedicationData] = useState({
    // dexamethasoneOral: [],
    // dexamethasoneIntra: [],
    // hydrocortisoneOral: [],
    // hydrocortisoneIntra: [],
    // prednisoneOral: [],
    // prednisoneIntra: [],
    // prednisoloneOral: [],
    // prednisoloneIntra: [],
    // methylprednisoloneOral: [],
    // methylprednisoloneIntra: [],
  });

  const addRow = (idx, subindex, subSubMedicationTypeID) => {
    const updatedArray = [...medicationTypes];

    let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
    let innerSubSubMedArray = [
      ...innerSubMedArray[subindex].subSubMedicationTypes,
    ];
    const subsubindex = innerSubSubMedArray.findIndex(
      (item) => item.subSubMedicationTypeID === subSubMedicationTypeID
    );
    innerSubSubMedArray[subsubindex] = {
      ...innerSubSubMedArray[subsubindex],
      epcorsupportivecareDetails: [
        ...innerSubSubMedArray[subsubindex].epcorsupportivecareDetails,
        initialGlucocorticoidsRow,
      ],
    };

    innerSubMedArray[subindex] = {
      ...innerSubMedArray[subindex],
      subSubMedicationTypes: innerSubSubMedArray,
    };
    updatedArray[idx] = {
      ...updatedArray[idx],
      subMedicationTypes: innerSubMedArray,
    };
    setMedicationTypes(updatedArray);
    // if (medicationData[type].length < 10) {
    //   const updatedInitialGlucocorticoidsRow = {
    //     ...initialGlucocorticoidsRow,
    //    // name: medicationData[type][0].name, // or whatever new value you want to assign
    //   };

    //   setMedicationData((prevData) => ({
    //     ...prevData,
    //     [type]: [...prevData[type], updatedInitialGlucocorticoidsRow],
    //   }));
    // }
  };

  const deleteRow = (idx, subindex, subSubMedicationTypeID, index) => {
    const updatedArray = [...medicationTypes];

    let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
    let innerSubSubMedArray = [
      ...innerSubMedArray[subindex].subSubMedicationTypes,
    ];
    const subsubindex = innerSubSubMedArray.findIndex(
      (item) => item.subSubMedicationTypeID === subSubMedicationTypeID
    );
    //
    let epcorsupportivecareDetailsFilter = [
      ...innerSubSubMedArray[subsubindex].epcorsupportivecareDetails,
    ];
    epcorsupportivecareDetailsFilter.splice(index, 1);

    //
    innerSubSubMedArray[subsubindex] = {
      ...innerSubSubMedArray[subsubindex],
      epcorsupportivecareDetails: epcorsupportivecareDetailsFilter,
      // [
      //       ...innerSubSubMedArray[subsubindex].epcorsupportivecareDetails.slice(0, index),  // Copy items before the index
      //       ...innerSubSubMedArray[subsubindex].epcorsupportivecareDetails.slice(index + 1)
      // ]
    };
    innerSubMedArray[subindex] = {
      ...innerSubMedArray[subindex],
      subSubMedicationTypes: innerSubSubMedArray,
    };
    updatedArray[idx] = {
      ...updatedArray[idx],
      subMedicationTypes: innerSubMedArray,
    };
    setMedicationTypes(updatedArray);
  };

  // const updatedDetails = medicationData[type].filter(
  //   (_, idx) => idx !== index
  // );
  // setMedicationData((prevData) => ({
  //   ...prevData,
  //   [type]: updatedDetails,
  // }));

  const handleGlucoRepeaterFieldChange = (
    e,
    idx,
    subindex,
    subSubMedicationTypeID,
    index
  ) => {
    const { name, value } = e.target;

    // Create a deep copy of the current array for immutability
    const updatedArray = [...medicationTypes];

    // Access SubMedicationTypes and SubSubMedicationTypes
    const innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
    const innerSubSubMedArray = [
      ...innerSubMedArray[subindex].subSubMedicationTypes,
    ];
    const subsubindex = innerSubSubMedArray.findIndex(
      (item) => item.subSubMedicationTypeID === subSubMedicationTypeID
    );

    // Make a deep copy of the target SubSubMedicationTypes item
    const updatedSubSubMedication = { ...innerSubSubMedArray[subsubindex] };
    const supportiveCareDetails = [
      ...updatedSubSubMedication.epcorsupportivecareDetails,
    ];

    switch (name) {
      // id={`supportive_careRd_${idx}_${subidx}_${subsubidx}_${eidx}`}
      case "supportive_careRd_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].supportive_careRd = Number(value);
        supportiveCareDetails[index].supportive_care_date = DefaultDate;

        supportiveCareDetails[index].supportive_careUnkselect =
          Number(value) === 99 ? true : false;

        supportiveCareDetails[index].supportive_care_dtEmpty = false;
        supportiveCareDetails[index].supportive_careRdEmpty = false;

        supportiveCareDetails[index].supportive_care_dtAfterIndex = false;
        supportiveCareDetails[index].supportive_care_dtBefore_discnt_dt = false;
        break;

      // case "supportive_care_date_"+ idx + "_" + subindex + "_" + subsubindex+ "_"+index:
      //   supportiveCareDetails[index].supportive_care_date = value;
      //   break;

      case "dosage_quantityRd_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].dosage_quantityRd = Number(value);
        supportiveCareDetails[index].dosage_quantity_txt = "";

        supportiveCareDetails[index].dosage_quantityRdEmpty = false;
        supportiveCareDetails[index].dosage_quantity_txtEmpty = false;
        supportiveCareDetails[index].dose_quantity_Unkselect =
          Number(value) === 99 ? true : false;

        supportiveCareDetails[index].dose_quantityRange = false;
        break;

      case "dosage_quantity_txt_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].dosage_quantity_txt = value;
        supportiveCareDetails[index].dosage_quantityRd = 1;

        supportiveCareDetails[index].dosage_quantityRdEmpty = false;
        supportiveCareDetails[index].dosage_quantity_txtEmpty = false;
        supportiveCareDetails[index].dose_quantityRange = false;

        supportiveCareDetails[index].dose_quantity_Unkselect = false;

        break;

      case "dosefrequencyRd_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].dosefrequencyRd = Number(value);
        supportiveCareDetails[index].dosefrequency_oth_txt = "";

        supportiveCareDetails[index].dosefrequency_Unkselect =
          Number(value) === 99 ? true : false;
        supportiveCareDetails[index].dosefrequencyRdEmpty = false;
        supportiveCareDetails[index].dosefrequency_oth_txtEmpty = false;
        break;

      case "dosefrequency_oth_txt_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].dosefrequency_oth_txt = value;
        supportiveCareDetails[index].dosefrequencyRd = 4;

        supportiveCareDetails[index].dosefrequencyRdEmpty = false;
        supportiveCareDetails[index].dosefrequency_oth_txtEmpty = false;

        supportiveCareDetails[index].dosefrequency_Unkselect = false;
        break;

      case "durationprescribedRd_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].durationprescribedRd = Number(value);
        supportiveCareDetails[index].durationprescribed_txt = "";

        supportiveCareDetails[index].durationprescribedUnkselect =
          Number(value) === 99 ? true : false;
        supportiveCareDetails[index].durationprescribedRdEmpty = false;
        supportiveCareDetails[index].durationprescribed_txtEmpty = false;
        break;

      case "durationprescribed_txt_" +
        idx +
        "_" +
        subindex +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        supportiveCareDetails[index].durationprescribed_txt = value;
        supportiveCareDetails[index].durationprescribedRd = 1;

        supportiveCareDetails[index].durationprescribedUnkselect = false;
        supportiveCareDetails[index].durationprescribedRdEmpty = false;
        supportiveCareDetails[index].durationprescribed_txtEmpty = false;
        break;

      default:
        break;
    }
    // Update epcorsupportivecareDetails in SubSubMedication
    innerSubSubMedArray[subsubindex] = updatedSubSubMedication;
    innerSubMedArray[subindex].subSubMedicationTypes = innerSubSubMedArray;
    updatedArray[idx].subMedicationTypes = innerSubMedArray;

    //  innerSubMedArray[subindex].SubSubMedicationTypes = innerSubSubMedArray;
    //  updatedArray[idx].SubMedicationTypes = innerSubMedArray;

    // Set updated medicationTypes array
    setMedicationTypes(updatedArray);

    // // Update the row in the copied array
    // updatedArray[idx] = updatedRow;

    // // Set the state with updated medication data, ensuring immutability
    // setMedicationData(prevData => ({
    //   ...prevData,
    //   [type]: updatedArray,
    // }));
  };

  // const handleGlucoRepeaterDateChange = (date, type, index) => {
  //   const updatedDetails = medicationData[type].map((item, idx) =>
  //     idx === index ? { ...item, supportive_care_date: date } : item
  //   );
  //   setMedicationData((prevData) => ({
  //     ...prevData,
  //     // submedtyeid:
  //     [type]: updatedDetails,
  //   }));
  // };

  const handleGlucoRepeaterDateChange = (name, date, datePart) => {
    const parts = name?.split("_") ?? [];
    let idx = parts[1] ?? "";
    let subidx = parts[2] ?? "";
    let subSubMedicationTypeID = parts[3] ?? "";
    let index = parts[4] ?? "";

    const lastIndex = name.lastIndexOf("-");

    // Create a deep copy of the current array for immutability
    const updatedArray = [...medicationTypes];

    // Access SubMedicationTypes and SubSubMedicationTypes
    const innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
    const innerSubSubMedArray = [
      ...innerSubMedArray[subidx].subSubMedicationTypes,
    ];

    const subsubidx = innerSubSubMedArray.findIndex(
      (item) => item.subSubMedicationTypeID == subSubMedicationTypeID
    );
    // Make a deep copy of the target SubSubMedicationTypes item
    const updatedSubSubMedication = { ...innerSubSubMedArray[subsubidx] };
    const supportiveCareDetails = [
      ...updatedSubSubMedication.epcorsupportivecareDetails,
    ];

    switch (name) {
      case "supportive-care-date_" +
        idx +
        "_" +
        subidx +
        "_" +
        subSubMedicationTypeID +
        "_" +
        index:
        {
          supportiveCareDetails[index].supportive_care_date = SetDateState(
            supportiveCareDetails[index].supportive_care_date,
            date,
            datePart
          );
          supportiveCareDetails[index].supportive_careRd = 1;

          supportiveCareDetails[index].supportive_care_dtEmpty = false;
          supportiveCareDetails[index].supportive_careRdEmpty = false;

          supportiveCareDetails[index].supportive_care_dtAfterIndex = false;
          supportiveCareDetails[
            index
          ].supportive_care_dtBefore_discnt_dt = false;

          supportiveCareDetails[index].supportive_careUnkselect = false;
        }
        break;
      default:
        break;
    }

    innerSubSubMedArray[subsubidx] = updatedSubSubMedication;
    innerSubMedArray[subidx].subSubMedicationTypes = innerSubSubMedArray;
    updatedArray[idx].subMedicationTypes = innerSubMedArray;

    // Set updated medicationTypes array
    setMedicationTypes(updatedArray);
  };

  // const SupportiveRow = {
  //   epcoritamabtreatment_medicationtypeid: 0, // pk of child table
  //   medicationTypeID: 0,
  //   submedicationtypeid:0,
  //   subsubmedicationtypeid:0,
  //   medicationtype_othtxt:"",
  //   submedicationtype_othtxt:"",
  //   medicationRd:null,
  // };
  // const [supportiveDetails, setSupportiveDetails] = useState([
  //   SupportiveRow,
  // ]);

  const initialState = {
    treatmentMedicationBridgeId: null,
    PatientId: null,
    medicationTypeID: null,
    medicationTypeOther: "",
    isSelected: false,
    medication_none: null,
    medication_Unk: null,
    epco_sub_empty: false, //hard err
    epco_sub_oth_txt_empty: false, //hard err

    subMedicationTypes: [
      {
        subMedicationTypeID: null,
        name: "",
        isSelected: false,
        subMedicationTypeOther: "",
        // epco_sub_empty: false,//hard err
        // epco_sub_oth_txt_empty: false,//hard err
        epco_sub_sub_empty: false, //hard err
        subSubMedicationTypes: [
          {
            subSubMedicationTypeID: null,
            name: "",
            isSelected: false,
            epcorsupportivecareDetails: [initialGlucocorticoidsRow], //remove initialGlucocorticoidsRow
            // epco_sub_sub_empty: false,//hard err
          },
        ],
      },
    ],
  };
  const [medicationTypes, setMedicationTypes] = useState([initialState]);
  //const [formData, setFormData] = useState(initialState);

  const [tocilizumabChecked, setTocilizumabChecked] = useState(false);
  const [tocSelected, setTocSelected] = useState(false);
  const handleInputChange = (
    e,
    medtypeid,
    name,
    idx,
    subindex,
    subsubindex,
    subMedTypeName,
    subMedicationTypeID
  ) => {
    const { id, value, checked } = e.target;
    let updatedArray = [...medicationTypes];
    const index = id.split("_").pop();

    debugger;
    switch (id) {
      case `medType_${name}_${index}`: {
        let epco_parent_othertext_empty =
          hardErrList.epco_parent_othertext_empty;

        updatedArray[idx].isSelected = !updatedArray[idx].isSelected;
        updatedArray[idx].epco_sub_empty = false;
        updatedArray[idx].epco_sub_oth_txt_empty = false;

        if (idx === 5 && updatedArray[idx].isSelected === true) {
          setTocSelected(true);
        } else {
          setTocSelected(false);
        }

        if (medtypeid === MedicationType.Glucocorticoids) {
          updatedArray[idx].subMedicationTypes = updatedArray[
            idx
          ].subMedicationTypes.map((subMed) => ({
            ...subMed,
            isSelected: false,
            subMedicationTypeOther: "",
            epco_sub_sub_empty: false,
            subSubMedicationTypes: subMed.subSubMedicationTypes.map(
              (subSubMed) => ({
                ...subSubMed,
                isSelected: false,
              })
            ),
          }));
        }

        if (medtypeid == MedicationType.Other) {
          updatedArray[idx] = {
            ...updatedArray[idx],
            medicationTypeOther: "",
          };
          epco_parent_othertext_empty = false;
        }

        if (medtypeid === MedicationType.Tocilizumab) {
          setTocilizumabChecked(updatedArray[idx].isSelected);
          setepcoritamabDetails((prev) => ({
            ...prev,
            dosesOfTocilizumabNumOfDoses: null,
          }));

          setSoftErrList((prev) => ({
            ...prev,
            epco_doses_of_tocilizumab_unknown: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
            dosesOfTocilizumabNumOfDoses_Empty: false,
          }));
        }
        // if (medtypeid === MedicationType.Tocilizumab && !updatedArray[idx].isSelected) {
        //   setepcoritamabDetails((prev) => ({
        //     ...prev,
        //     dosesOfTocilizumabNumOfDoses: null,
        //   }));

        // setSoftErrList((prev) => ({
        //   ...prev,
        //   epco_doses_of_tocilizumab_unknown: false,
        // }));
        // }

        setepcoritamabDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty,
        }));
        break;
      }

      case `medType_txt_${index}`: {
        updatedArray[idx].medicationTypeOther = value;
        updatedArray[idx].isSelected = true; //Boolean(value);

        setepcoritamabDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          epco_parent_empty: false,
          epco_parent_othertext_empty: false,
        }));
        break;
      }

      case `subMedType_${name}_${index}`: {
        // Update the sub-medication type at the specified subindex
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        let epco_sub_oth_txt_empty = updatedArray[idx].epco_sub_oth_txt_empty;
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          isSelected: !innerSubMedArray[subindex].isSelected,
          subSubMedicationTypes: innerSubMedArray[
            subindex
          ].subSubMedicationTypes.map((a) => ({
            ...a,
            isSelected: false,
          })),
          epco_sub_sub_empty: false,
        };
        if (subMedicationTypeID == SupportiveCareEpcoritamab.Other) {
          innerSubMedArray[subindex] = {
            ...innerSubMedArray[subindex],
            subMedicationTypeOther: "",
          };
          epco_sub_oth_txt_empty = false;
        }
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          epco_sub_empty: false,
          epco_sub_oth_txt_empty,
        };
        setepcoritamabDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        break;
      }

      case `subMedType_txt_${index}`: {
        // Update the SubMedicationTypeOther for a specific sub-medication type
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          subMedicationTypeOther: value,
          isSelected: true, //Boolean(value)
        };
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          epco_sub_empty: false,
          epco_sub_oth_txt_empty: false,
        };
        setepcoritamabDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));
        break;
      }

      case `subSubMedType_${name}_${index}`: {
        // Update the sub-sub-medication type at the specified subsubindex within a specific sub-medication
        let innerSubMedArray = [...updatedArray[idx].subMedicationTypes];
        let innerSubSubMedArray = [
          ...innerSubMedArray[subindex].subSubMedicationTypes,
        ];

        let epcorsupportivecareDetails = [];
        if (checked) {
          epcorsupportivecareDetails = [{ ...initialGlucocorticoidsRow }];
        }
        innerSubSubMedArray[subsubindex] = {
          ...innerSubSubMedArray[subsubindex],
          isSelected: !innerSubSubMedArray[subsubindex].isSelected,
          epcorsupportivecareDetails,
        };
        innerSubMedArray[subindex] = {
          ...innerSubMedArray[subindex],
          subSubMedicationTypes: innerSubSubMedArray,
          epco_sub_sub_empty: false,
        };
        updatedArray[idx] = {
          ...updatedArray[idx],
          subMedicationTypes: innerSubMedArray,
          // epcorsupportivecareDetails:medicationData
        };

        setepcoritamabDetails((prev) => ({
          ...prev,
          medicationUnk: false,
          medicationNone: false,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          epco_parent_unknown: false,
        }));

        break;
      }

      default:
        break;
    }

    setMedicationTypes(updatedArray);
  };

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.EpcoritamabTreatment} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Epcoritamab Treatment</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to provide information
                      on the epcoritamab treatment the patient received. You
                      will also be asked to provide information on any
                      additional treatment received as combination therapy with
                      epcoritamab.
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q36.</span>
                        <span className="quest-text-pad">
                          In which line of therapy (LOT) did the patient receive
                          epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q36Select_1"
                            name="q36_state"
                            value={1}
                            checked={epcoritamabDetails.q36_state === 1}
                            onChange={HandleFieldChange}
                          />

                          {/* <label htmlFor="q36Select_1">
                            Select LOT */}
                          <span className="radio-text-padding ">
                            <Dropdown className="custom-dropdown-caret">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="w110 epcoritamab-custom-dropdown-button"
                              >
                                {epcoritamabDetails.selectedLOT !== null
                                  ? epcoritamabDetails.selectedLOT
                                  : "Select LOT"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="epcoritamab-custom-dropdown-menu">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                                  <Dropdown.Item
                                    as="button"
                                    name="selectedLOT"
                                    key={num}
                                    value={num}
                                    className={`custom-dropdown-item ${
                                      num === epcoritamabDetails.selectedLOT &&
                                      "custom-dropdown-item-selected"
                                    }`}
                                    style={{ width: "100% !important" }}
                                    onClick={HandleFieldChange}
                                  >
                                    {num}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                          {/* </label> */}
                        </div>
                        {/* <div className="">
                          <input
                            type="radio"
                            id="q36Select_2"
                            name="q36_state"
                            value={2}
                            checked={
                              epcoritamabDetails.q36_state === 2
                            }
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q36Select_2">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q36_other_txt"
                                type="text"
                                value={
                                  epcoritamabDetails.q36_other_txt }
                                //maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div> */}
                      </div>
                      <ErrorField
                        show={hardErrList.q36Empty}
                        message={Epcoritamab_Msgs.Q36}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q37.</span>
                        <span className="quest-text-pad">
                          Did the patient receive epcoritamab in combination
                          with any other treatment?{" "}
                          <i>(select all that apply)</i>
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q37Mono"
                            name="q37Mono"
                            value={1}
                            checked={epcoritamabDetails.q37Mono === 1}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Mono">
                            <span className="radio-text-padding">
                              The patient received the treatment as monotherapy
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Lena"
                            name="q37Lena"
                            checked={epcoritamabDetails.q37Lena}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Lena">
                            <span className="radio-text-padding">
                              Lenalidomide
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Lonca"
                            name="q37Lonca"
                            checked={epcoritamabDetails.q37Lonca}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Lonca">
                            <span className="radio-text-padding">
                              Loncastuximab
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Ritux"
                            name="q37Ritux"
                            checked={epcoritamabDetails.q37Ritux}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Ritux">
                            <span className="radio-text-padding">
                              Rituximab, cyclophosphamide, doxorubicin
                              hydrochloride, vincristine and prednisone (R-CHOP)
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37Vene"
                            name="q37Vene"
                            checked={epcoritamabDetails.q37Vene}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37Vene">
                            <span className="radio-text-padding">
                              Venetoclax
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q37InvestAgent"
                            name="q37InvestAgent"
                            checked={epcoritamabDetails.q37InvestAgent}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q37InvestAgent">
                            <span className="radio-text-padding">
                              Investigational agent:{" "}
                              <input
                                className="input-dash"
                                name="q37InvestAgent_txt"
                                type="text"
                                value={epcoritamabDetails.q37InvestAgent_txt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="raceOther"
                            name="q37Other"
                            checked={epcoritamabDetails.q37Other}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="raceOther">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q37Other_txt"
                                type="text"
                                value={epcoritamabDetails.q37Other_txt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                      </div>

                      <ErrorField
                        show={hardErrList.q37Empty}
                        message={Epcoritamab_Msgs.Q37}
                        style={{ paddingLeft: "24px" }}
                      />
                      <ErrorField
                        show={hardErrList.q37EmptyagentTxt}
                        message="Please enter Investigational agent."
                      />
                      <ErrorField
                        show={hardErrList.q37EmptyOtherTxt}
                        message="Please specify other text."
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q38.</span>
                        <span className="quest-text-pad">
                          How many epcoritamab step-up doses were received by
                          the patient?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q38Unk_99"
                              name="q38step_up"
                              value={1}
                              checked={epcoritamabDetails.q38step_up === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Step-up doses:
                            </span>
                            <span>
                              <input
                                className="sub-input-dash"
                                name="q38_step_up_txt"
                                type="text"
                                value={epcoritamabDetails.q38_step_up_txt}
                                maxLength={2}
                                onChange={HandleFieldChange}
                                min="1"
                                max="10"
                                onKeyDown={(e) => {
                                  // Allow backspace, arrow keys, and delete
                                  if (
                                    [
                                      "Backspace",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "Delete",
                                      "Tab",
                                    ].includes(e.key)
                                  ) {
                                    return;
                                  }
                                  // Prevent non-numeric characters
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                    return;
                                  }
                                  const value = e.target.value;
                                  const selectionStart =
                                    e.target.selectionStart;
                                  const newValue =
                                    value.slice(0, selectionStart) +
                                    e.key +
                                    value.slice(e.target.selectionEnd);

                                  // Prevent numbers greater than 10
                                  if (
                                    Number(newValue) < 1 ||
                                    Number(newValue) > 10
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }
                                }}
                              />
                            </span>{" "}
                          </label>
                        </div>

                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q38Unk_99"
                              name="q38step_up"
                              value={99}
                              checked={epcoritamabDetails.q38step_up === 99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                      <ErrorField
                        show={softErrList.q38Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q38Empty}
                        message={Epcoritamab_Msgs.Q38}
                      />

                      <ErrorField
                        show={hardErrList.q38SizeOutOfRange}
                        message={Epcoritamab_Msgs.DaysRangeLimit}
                      />
                    </div>

                    {epcoritamabDetails.q38_step_up_txt > 0 &&
                      epcoritamabDetails.q38_step_up_txt != 1 && (
                        <div className="dependent-section">
                          {epcostepupdoseDetails &&
                            epcostepupdoseDetails.length > 0 &&
                            epcostepupdoseDetails.map((item, idx) => (
                              <div className="loop-section-ul">
                                <div className="loop-head"></div>
                                <div className="sub-question-bot-space answer-pad-left">
                                  <div className="question question-weight">
                                    <span>Q39.</span>
                                    <span className="quest-text-pad">
                                      How many days were there between the
                                      patient’s epcoritamab step-up dose{""}{" "}
                                      {idx + 1} and {idx + 2}?
                                    </span>
                                  </div>

                                  <div className=" double-dig-answer">
                                    <div className="">
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={
                                          "stepupdosedaysdifferenceRd" + idx
                                        }
                                        value={1}
                                        checked={
                                          item.stepupdosedaysdifferenceRd === 1
                                        }
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                      <span className="radio-text-padding">
                                        <input
                                          className="sub-input-dash"
                                          id={idx}
                                          name={
                                            "stepupdosedaysdifferenceTxt" + idx
                                          }
                                          type="text"
                                          value={
                                            item.stepupdosedaysdifferenceTxt
                                          }
                                          maxLength={8}
                                          onChange={HandleRepeaterFieldChange}
                                          onKeyDown={(e) => {
                                            // Allow backspace, arrow keys, and delete
                                            if (
                                              [
                                                "Backspace",
                                                "ArrowLeft",
                                                "ArrowRight",
                                                "Delete",
                                                "Tab",
                                              ].includes(e.key)
                                            ) {
                                              return;
                                            }
                                            // Prevent non-numeric characters
                                            if (!/[0-9]/.test(e.key)) {
                                              e.preventDefault();
                                              return;
                                            }
                                            const value = e.target.value;
                                            const selectionStart =
                                              e.target.selectionStart;
                                            const newValue =
                                              value.slice(0, selectionStart) +
                                              e.key +
                                              value.slice(
                                                e.target.selectionEnd
                                              );
                                            // Prevent multiple '.'
                                            if (
                                              e.key === "." &&
                                              value.includes(".")
                                            ) {
                                              e.preventDefault();
                                              return;
                                            }
                                            // Prevent '.' as the first character
                                            if (e.key === "." && value === "") {
                                              e.preventDefault();
                                              return;
                                            }
                                            // Allow only one digit after decimal point
                                            if (
                                              newValue.includes(".") &&
                                              newValue.split(".")[1].length > 2
                                            ) {
                                              e.preventDefault();
                                              return;
                                            }
                                          }}
                                        />
                                      </span>{" "}
                                      days
                                    </div>
                                    <div className="">
                                      <label>
                                        <input
                                          type="radio"
                                          id={idx}
                                          name={
                                            "stepupdosedaysdifferenceRd" + idx
                                          }
                                          value={99}
                                          checked={
                                            item.stepupdosedaysdifferenceRd ===
                                            99
                                          }
                                          onChange={HandleRepeaterFieldChange}
                                        />
                                        <span className="radio-text-padding">
                                          Unknown
                                        </span>
                                      </label>
                                    </div>
                                  </div>

                                  <ErrorField
                                    show={item.stepupdosedaysdifferenceRdEmpty}
                                    message={Epcoritamab_Msgs.specifyDaysHard
                                      .replace("{day1}", idx + 1)
                                      .replace("{day2}", idx + 2)}
                                  />

                                  <ErrorField
                                    show={item.stepupdosedaysdifferencetxtEmpty}
                                    message={Epcoritamab_Msgs.specifyDaysHard
                                      .replace("{day1}", idx + 1)
                                      .replace("{day2}", idx + 2)}
                                  />

                                  <ErrorField
                                    show={item.stepupdosedaysdifferenceTxtRange}
                                    message={Epcoritamab_Msgs.DaysRangeLimit}
                                  />

                                  <ErrorField
                                    show={item.stepupdosedaysdifferenceUnkSoft}
                                    message={CommonError_Msgs.UnknownSelect}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      )}

                    {epcoritamabDetails.q38step_up !== 99 && (
                      <>
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q40.</span>
                            <span className="quest-text-pad">
                              How many days were there between the patient’s
                              epcoritamab step-up dose{" "}
                              {epcoritamabDetails.q38_step_up_txt} and the
                              patient’s
                              <span
                                className="histologic-grade-container"
                                onClick={() =>
                                  handleTooltipToggle("step up dose")
                                }
                                onMouseEnter={() =>
                                  handleMouseEnter("step up dose")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave("step up dose")
                                }
                              >
                                {" "}
                                <span className="histologic-grade">
                                  full dose
                                </span>
                                {isTooltipVisible === "step up dose" ? (
                                  <div className="custom-tool-tip">
                                    The full dose refers to the initial standard
                                    therapeutic dose of epcoritamab <br />
                                    given to patients after completing a series
                                    of step-up doses.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </span>
                              ?
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="">
                              <input
                                type="radio"
                                id="q39Select_1"
                                name="q39step_up"
                                value={1}
                                checked={epcoritamabDetails.q39step_up === 1}
                                onChange={HandleFieldChange}
                              />
                              {/* <label htmlFor="q39Select_1">
                            <span className="radio-text-padding">Days:</span>
                          </label>  */}
                              <span className="radio-text-padding">
                                <input
                                  className="sub-input-dash"
                                  name="q39_days_txt"
                                  type="text"
                                  value={epcoritamabDetails.q39_days_txt}
                                  maxLength={100}
                                  onChange={HandleFieldChange}
                                />
                              </span>{" "}
                              days
                            </div>
                            <div className="">
                              <label>
                                <input
                                  type="radio"
                                  id="q39Unk_99"
                                  name="q39step_up"
                                  value={99}
                                  checked={epcoritamabDetails.q39step_up === 99}
                                  onChange={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                          <ErrorField
                            show={softErrList.q39Unk_err}
                            message={CommonMsgs.Unknown}
                          />

                          <ErrorField
                            show={hardErrList.q39Empty}
                            message={Epcoritamab_Msgs.Q39.replace(
                              "{id}",
                              epcoritamabDetails.q38_step_up_txt
                            )}
                          />
                          <ErrorField
                            show={hardErrList.q39SizeOutOfRange}
                            message={Epcoritamab_Msgs.DaysRangeLimit}
                          />
                        </div>
                      </>
                    )}

                    {epcoritamabDetails.q38step_up !== 99 && (
                      <>
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q41.</span>
                            <span className="quest-text-pad">
                              Please select the site of care for each dose:
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <table className="styled-table">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Site of care</b>
                                  </td>
                                  <td>
                                    <b>Outpatient</b>
                                  </td>
                                  <td>
                                    <b>Inpatient</b>
                                  </td>
                                  <td>
                                    <b>Unknown</b>
                                  </td>
                                </tr>

                                {epcoritamabDetails.q38step_up === 1 &&
                                  epcoritamabDetails.q38_step_up_txt > 0 &&
                                  stepupdoseAssessment &&
                                  stepupdoseAssessment.length > 0 &&
                                  stepupdoseAssessment.map((item, idx) => (
                                    <tr>
                                      <td>
                                        Step-up dose {idx + 1}
                                        <ErrorField
                                          show={item.siteofcare_doseRdEmpty}
                                          message={
                                            Epcoritamab_Msgs.epco_site_of_care_for_each_dose_empty
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="radio"
                                          name={"siteofcare_doseRd" + idx}
                                          id={idx}
                                          value={1}
                                          checked={item.siteofcare_doseRd === 1}
                                          onChange={HandleRepeaterFieldChange}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="radio"
                                          name={"siteofcare_doseRd" + idx}
                                          id={idx}
                                          value={2}
                                          checked={item.siteofcare_doseRd === 2}
                                          onChange={HandleRepeaterFieldChange}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="radio"
                                          name={"siteofcare_doseRd" + idx}
                                          id={idx}
                                          value={99}
                                          checked={
                                            item.siteofcare_doseRd === 99
                                          }
                                          onChange={HandleRepeaterFieldChange}
                                        />
                                      </td>
                                    </tr>
                                  ))}

                                <tr>
                                  <td>
                                    <span
                                      className="histologic-grade-container"
                                      onClick={() =>
                                        handleTooltipToggle(
                                          "Full Dose Site of Care"
                                        )
                                      }
                                      onMouseEnter={() =>
                                        handleMouseEnter(
                                          "Full Dose Site of Care"
                                        )
                                      }
                                      onMouseLeave={() =>
                                        handleMouseLeave(
                                          "Full Dose Site of Care"
                                        )
                                      }
                                    >
                                      {" "}
                                      <span className="histologic-grade">
                                        Full dose
                                      </span>
                                      {isTooltipVisible ===
                                      "Full Dose Site of Care" ? (
                                        <div className="tool-tip-custom">
                                          The full dose refers to the initial
                                          standard therapeutic dose of
                                          epcoritamab
                                          <br /> given to patients after
                                          completing a series of step-up doses.
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    <ErrorField
                                      show={hardErrList.SiteOfCareEmpty}
                                      message={
                                        Epcoritamab_Msgs.epco_site_of_care_for_each_dose_empty
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="radio"
                                      name="siteOfCare_FullDose"
                                      id="siteOfCare_FullDose_Out"
                                      value={1}
                                      checked={
                                        epcoritamabDetails.siteOfCare_FullDose ===
                                        1
                                      }
                                      onChange={HandleFieldChange}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="radio"
                                      name="siteOfCare_FullDose"
                                      id="siteOfCare_FullDose_In"
                                      value={2}
                                      checked={
                                        epcoritamabDetails.siteOfCare_FullDose ===
                                        2
                                      }
                                      onChange={HandleFieldChange}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="radio"
                                      name="siteOfCare_FullDose"
                                      id="siteOfCare_FullDose_Unk"
                                      value={99}
                                      checked={
                                        epcoritamabDetails.siteOfCare_FullDose ===
                                        99
                                      }
                                      onChange={HandleFieldChange}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                        </div>
                      </>
                    )}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q42.</span>
                        <span className="quest-text-pad">
                          Did the patient discontinue epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q40DiscontSelect_1"
                              name="q40Discont"
                              value={1}
                              // checked={true}
                              checked={epcoritamabDetails.q40Discont === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q40DiscontSelect_0"
                              name="q40Discont"
                              value={0}
                              checked={epcoritamabDetails.q40Discont === 0}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              No, the patient is still on treatment
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="q40DiscontSelect_99"
                              name="q40Discont"
                              value={99}
                              checked={epcoritamabDetails.q40Discont === 99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={softErrList.q40Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q40Empty}
                        message={Epcoritamab_Msgs.Q40}
                      />
                    </div>

                    {epcoritamabDetails.q40Discont === 1 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q43.</span>
                          <span className="quest-text-pad">
                            What was the date of epcoritamab discontinuation?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="q41EpDiscontDtUnk_1"
                              name="q41EpDiscontDtUnk"
                              value={1}
                              checked={
                                epcoritamabDetails.q41EpDiscontDtUnk === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"dt_discont"}
                                HandleDateChange={HandleDateChange}
                                date={epcoritamabDetails.dt_discont}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>
                                If the exact month and/or day is unknown, please
                                enter UNK.
                              </i> */}
                            </span>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="q41EpDiscontDtUnk_99"
                                name="q41EpDiscontDtUnk"
                                value={99}
                                checked={
                                  epcoritamabDetails.q41EpDiscontDtUnk === 99
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>

                        <ErrorField
                          show={softErrList.q41Unk_err}
                          message={CommonMsgs.Unknown}
                        />

                        <ErrorField
                          show={hardErrList.q41Empty}
                          message={Epcoritamab_Msgs.Q41}
                        />
                        <ErrorField
                          show={hardErrList.q41_date_afterIndex}
                          message={Epcoritamab_Msgs.Q41_Invalid1}
                        />
                        <ErrorField
                          show={hardErrList.q41_date_beforeDeathorChart}
                          message={Epcoritamab_Msgs.Q41_Invalid2}
                        />
                      </div>
                    )}
                    {epcoritamabDetails.q40Discont === 1 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q44.</span>
                          <span className="quest-text-pad">
                            What was the reason for discontinuation of
                            epcoritamab?<i>(select all that apply)</i>
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontLbcl_Select_1"
                                name="epcoDiscontLbcl"
                                value={1}
                                checked={epcoritamabDetails.epcoDiscontLbcl}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                LBCL disease progression
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontEligibility_Select_2"
                                name="epcoDiscontEligibility"
                                value={2}
                                checked={
                                  epcoritamabDetails.epcoDiscontEligibility
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Eligibility for clinical trial treatment
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontTransplant_Select_3"
                                name="epcoDiscontTransplant"
                                value={3}
                                checked={
                                  epcoritamabDetails.epcoDiscontTransplant
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Decision to proceed with transplant
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCompleteResponse_Select_4"
                                name="epcoDiscontCompleteResponse"
                                value={4}
                                checked={
                                  epcoritamabDetails.epcoDiscontCompleteResponse
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Clinical decision as patient experienced
                                complete response
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontInadequateResponse_Select_5"
                                name="epcoDiscontInadequateResponse"
                                value={5}
                                checked={
                                  epcoritamabDetails.epcoDiscontInadequateResponse
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Physician decision due to inadequate response
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCovid19Infection_Select_6"
                                name="epcoDiscontCovid19Infection"
                                value={6}
                                checked={
                                  epcoritamabDetails.epcoDiscontCovid19Infection
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                COVID-19 infection
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontCartTherapy_Select_7"
                                name="epcoDiscontCartTherapy"
                                value={7}
                                checked={
                                  epcoritamabDetails.epcoDiscontCartTherapy
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Decided to proceed with CAR T cell therapy
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontPatientChoiceDecision_Select_8"
                                name="epcoDiscontPatientChoiceDecision"
                                value={8}
                                checked={
                                  epcoritamabDetails.epcoDiscontPatientChoiceDecision
                                }
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Patient’s choice/decision
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="checkbox"
                                id="epcoDiscontOther_Select_9"
                                name="epcoDiscontOther"
                                value={9}
                                checked={epcoritamabDetails.epcoDiscontOther}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Other, specify:{" "}
                                <input
                                  className="input-dash"
                                  name="epcoDiscontReasonOtherTxt"
                                  type="text"
                                  maxLength={100}
                                  value={
                                    epcoritamabDetails.epcoDiscontReasonOtherTxt
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <label>
                              <input
                                type="radio"
                                id="epcoDiscontUnknown_99"
                                name="epcoDiscontUnknown"
                                //   value={99}
                                checked={epcoritamabDetails.epcoDiscontUnknown}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>

                        <ErrorField
                          show={hardErrList.epco_discont_reasonEmpty}
                          message={Epcoritamab_Msgs.epco_discont_reasonEmpty}
                        />
                        <ErrorField
                          show={softErrList.epco_discont_unk_err}
                          message={CommonMsgs.Unknown}
                        />
                        <ErrorField
                          show={hardErrList.epco_discont_reasonEmpty_txtEmpty}
                          message={Epcoritamab_Msgs.q47TxtEmpty}
                        />
                      </div>
                    )}
                    {/* Set 1 start */}

                    <div className="dependent-section">
                      {epcorAssessmentsDetails &&
                        epcorAssessmentsDetails.length > 0 &&
                        epcorAssessmentsDetails.map((data, idx) => (
                          <div className="loop-section-ul">
                            <div className="loop-head">
                              {/* <span className="question-weight">1<sup>st</sup> Radiological Assessment</span> */}
                              <span className="question-weight">
                                {GetOrdinal(idx + 1)} Radiological Assessment
                              </span>
                              {idx != 0 && (
                                <div className="delete-btn-div">
                                  <img
                                    width="16px"
                                    src="../Assets/images/Icon-material-delete.png"
                                    alt=""
                                    onClick={() =>
                                      DeleteEPCORAssessmentRow(idx)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q45.</span>
                                <span className="quest-text-pad">
                                  What was the date of the{" "}
                                  {GetOrdinalInWord(idx + 1)} radiological
                                  assessment after the initiation of epcoritamab
                                  on {""}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {index_date}
                                  </span>
                                  ?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="date-field-padding">
                                  <input
                                    type="radio"
                                    id={idx}
                                    name={"radiological_assess_dx" + idx}
                                    value={1}
                                    checked={data.radiological_assess_dx === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    <DateControl
                                      ctrlId={"radiological_dx_date-" + idx}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      date={data.radiological_assess_dx_date}
                                      showUnkInfoTxt={true}
                                    />
                                    {/* <i>
                                      If the exact month and/or day is unknown,
                                      please enter UNK.
                                    </i> */}
                                  </span>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"radiological_assess_dx" + idx}
                                      value={99}
                                      checked={
                                        data.radiological_assess_dx === 99
                                      }
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.radiologyDxDateUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.radiologyDxEmptyHard}
                                message={Epcoritamab_Msgs.radiologyDxEmpty.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />

                              <ErrorField
                                show={data.q43afterindexEmptyHard}
                                message={Epcoritamab_Msgs.After_index_dt.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                              <ErrorField
                                show={data.q43beforeQ41dateEmptyHard}
                                message={Epcoritamab_Msgs.Before_discnt_dt.replace(
                                  "{id}",
                                  GetOrdinalInWord(idx + 1)
                                )}
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q46.</span>
                                <span className="quest-text-pad">
                                  Which of the following categories best
                                  describes the patient’s LBCL disease status at
                                  this radiological assessment? This can be
                                  based on the conclusion of the radiologic
                                  report or physician’s notes in the chart.
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={1}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 1}
                                    />

                                    <span className="radio-text-padding">
                                      Clinician-assessed complete response
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={2}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 2}
                                    />

                                    <span className="radio-text-padding">
                                      Clinician-assessed less-than-complete
                                      response (significant tumor shrinkage)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label className="long-text-option">
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={3}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 3}
                                    />
                                    <span className="radio-text-padding">
                                      Clinician-assessed stable disease (may
                                      include minimal increases or decreases in
                                      size of tumor or permitting ongoing
                                      systemic therapy)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={4}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 4}
                                    />

                                    <span className="radio-text-padding">
                                      Progressive disease
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={5}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 5}
                                    />

                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"q44_lbcl_oth_txt"}
                                        id={idx}
                                        type="text"
                                        maxLength={100}
                                        value={data.q44_lbcl_oth_txt}
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q44_LBCL_status" + idx}
                                      value={99}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q44_LBCL_status === 99}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q44StatusEmptyHard}
                                message={Epcoritamab_Msgs.q44HardEmpty}
                              />
                              <ErrorField
                                show={data.q44StatusUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q44lbclTxtEmptyHard}
                                message={Epcoritamab_Msgs.q44lbclTxtEmpty}
                              />
                            </div>
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q47.</span>
                                <span className="quest-text-pad">
                                  Was the disease stage assessed per LYRIC or
                                  Lugano criteria?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={1}
                                      checked={data.q45_stage_status === 1}
                                      onChange={HandleRepeaterFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      LYRIC
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={2}
                                      checked={data.q45_stage_status === 2}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Lugano
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={3}
                                      checked={data.q45_stage_status === 3}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      LYRIC and Lugano
                                    </span>
                                  </label>
                                </div>

                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={99}
                                      checked={data.q45_stage_status === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>

                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q45_stage_status" + idx}
                                      value={4}
                                      checked={data.q45_stage_status === 4}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Neither
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q45statusEmptyHard}
                                message={Epcoritamab_Msgs.q45StatusEmpty}
                              />
                              <ErrorField
                                show={data.q46UnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                            </div>
                            {showQ46[idx] && (
                              <div className="sub-question-bot-sapce answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q48.</span>
                                  <span className="quest-text-pad">
                                    What was the disease assessment per LYRIC
                                    criteria?
                                  </span>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={1}
                                        checked={data.q46LyricAssess === 1}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR1
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={2}
                                        checked={data.q46LyricAssess === 2}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR2
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={3}
                                        checked={data.q46LyricAssess === 3}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR1 and IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={4}
                                        checked={data.q46LyricAssess === 4}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR2 and IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={5}
                                        checked={data.q46LyricAssess === 5}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        IR3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={6}
                                        checked={data.q46LyricAssess === 6}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Stable disease
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={7}
                                        checked={data.q46LyricAssess === 7}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Partial response
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={8}
                                        checked={data.q46LyricAssess === 8}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Complete response
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className=" double-dig-answer">
                                  <div className="">
                                    <label>
                                      <input
                                        type="radio"
                                        id={idx}
                                        name={"q46LyricAssess" + idx}
                                        value={9}
                                        checked={data.q46LyricAssess === 9}
                                        onChange={HandleRepeaterFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Progressive disease
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={data.q46LyricEmptyHard}
                                  message={Epcoritamab_Msgs.q46LyricEmptyHard}
                                />
                              </div>
                            )}

                            {/* {showQ47[idx] && ( */}
                            <div className="sub-question-bot-sapce answer-pad-left">
                              <div className="question question-weight">
                                <span>Q49.</span>
                                <span className="quest-text-pad">
                                  What type of radiological assessment was used?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={1}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 1}
                                    />

                                    <span className="radio-text-padding">
                                      PET or PET/CT
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={2}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 2}
                                    />

                                    <span className="radio-text-padding">
                                      CT
                                    </span>
                                  </label>
                                </div>
                                {/* <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={3}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 3}
                                    />

                                    <span className="radio-text-padding">
                                      Other imaging
                                    </span>
                                  </label>
                                </div> */}
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={4}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 4}
                                    />

                                    <span className="radio-text-padding">
                                      Clinical only (no imaging)
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={5}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 5}
                                    />

                                    <span className="radio-text-padding">
                                      Other, specify:{" "}
                                      <input
                                        className="input-dash"
                                        name={"q47RadioAssessTypeOtherTxt"}
                                        id={idx}
                                        type="text"
                                        maxLength={100}
                                        value={data.q47RadioAssessTypeOtherTxt}
                                        onChange={HandleRepeaterFieldChange}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <label>
                                    <input
                                      type="radio"
                                      id={idx}
                                      name={"q47_radiology_type" + idx}
                                      value={99}
                                      onChange={HandleRepeaterFieldChange}
                                      checked={data.q47_radiology_type === 99}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q47radiologyTypeEmptyHard}
                                message={Epcoritamab_Msgs.q47HardEmpty}
                              />
                              <ErrorField
                                show={data.q47radiologyTypeUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />
                              <ErrorField
                                show={data.q47radiologyTypeTxtEmptyHard}
                                message={Epcoritamab_Msgs.q47TxtEmpty}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        ))}
                      {epcorAssessmentsDetails &&
                        epcorAssessmentsDetails.length < 8 && (
                          <div className="sub-question-bot-sapce-ul mt-2">
                            <div className="answer-list-text">
                              <a onClick={AddEPCORAssessmentRow}>
                                <img
                                  width="18px"
                                  src="../Assets/images/plus.png"
                                  alt=""
                                />
                              </a>
                              <a
                                onClick={AddEPCORAssessmentRow}
                                className="radio-text-padding"
                              >
                                Click here to add another radiological
                                assessment
                              </a>
                            </div>
                          </div>
                        )}

                      {/* Set 1 end */}
                    </div>

                    {/* Q50. */}
                    <div className="sub-question-bot-sapce answer-pad-left">
                      <div className="question question-weight">
                        <span>Q51.</span>
                        <span className="quest-text-pad">
                          Did the patient receive any of the following
                          supportive care or concomitant medication in addition
                          to epcoritamab?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        {medicationTypes.map((medType, idx) => (
                          <div key={idx}>
                            <label>
                              <input
                                type="checkbox"
                                id={`medType_${medType.name}_${idx}`}
                                checked={medType.isSelected}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    medType.medicationTypeID,
                                    medType.name,
                                    idx
                                  )
                                }
                              />
                              <span className="radio-text-padding">
                                {medType.name}
                              </span>

                              {medType.medicationTypeID ===
                                MedicationType.Other && (
                                //   selectedOptions.parent[medType.medicationTypeID] &&
                                <>
                                  , specify:
                                  <input
                                    type="text"
                                    className="input-dash"
                                    value={medType.medicationTypeOther}
                                    id={`medType_txt_${idx}`}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        medType.medicationTypeID,
                                        medType.name,
                                        idx
                                      )
                                    }
                                    placeholder=""
                                    maxLength={100}
                                  />
                                </>
                              )}
                            </label>
                            {medType.subMedicationTypes &&
                              medType.subMedicationTypes.length > 0 && (
                                <>
                                  <div className="child-checkbox">
                                    {medType.isSelected === true &&
                                      medType.medicationTypeID ===
                                        MedicationType.Glucocorticoids && (
                                        <>
                                          {medType?.subMedicationTypes?.map(
                                            (subMedType, sub_idx) => (
                                              <>
                                                <div key={sub_idx}>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      id={`subMedType_${subMedType.name}_${sub_idx}`}
                                                      checked={
                                                        subMedType.isSelected
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          medType.medicationTypeID,
                                                          subMedType.name,
                                                          idx,
                                                          sub_idx,
                                                          null,
                                                          subMedType.name,
                                                          subMedType.subMedicationTypeID
                                                        )
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      {subMedType.name}
                                                    </span>

                                                    {subMedType.subMedicationTypeID ===
                                                      SupportiveCareEpcoritamab.Other && (
                                                      <>
                                                        , specify:
                                                        <input
                                                          type="text"
                                                          className="input-dash"
                                                          id={`subMedType_txt_${idx}`}
                                                          value={
                                                            subMedType.subMedicationTypeOther
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              e,
                                                              medType.medicationTypeID,
                                                              subMedType.name,
                                                              idx,
                                                              sub_idx,
                                                              subMedType.name,
                                                              subMedType.subMedicationTypeID
                                                            )
                                                          }
                                                          placeholder=""
                                                          maxLength={100}
                                                        />
                                                      </>
                                                    )}
                                                  </label>
                                                  {medType.subMedicationTypes[
                                                    sub_idx
                                                  ]?.subSubMedicationTypes && (
                                                    <div className="child-checkbox">
                                                      {subMedType.isSelected && (
                                                        <>
                                                          {medType.subMedicationTypes[
                                                            sub_idx
                                                          ]?.subSubMedicationTypes.map(
                                                            (
                                                              subSubMedType,
                                                              sub_sub_idx
                                                            ) => (
                                                              <>
                                                                <div
                                                                  key={
                                                                    sub_sub_idx
                                                                  }
                                                                >
                                                                  <label>
                                                                    <input
                                                                      type="checkbox"
                                                                      // id={`subSubMedType-${subSubMedType.subSubMedicationTypeID}`}
                                                                      id={`subSubMedType_${subSubMedType.name}_${sub_sub_idx}`}
                                                                      checked={
                                                                        subSubMedType.isSelected
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleInputChange(
                                                                          e,
                                                                          medType.medicationTypeID,
                                                                          subSubMedType.name,
                                                                          idx,
                                                                          sub_idx,
                                                                          sub_sub_idx,
                                                                          subMedType.name,
                                                                          subMedType.subMedicationTypeID
                                                                        )
                                                                      }
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                      {
                                                                        subSubMedType.name
                                                                      }
                                                                    </span>
                                                                  </label>
                                                                </div>
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  )}
                                                  <ErrorField
                                                    show={
                                                      subMedType.epco_sub_sub_empty
                                                    }
                                                    message={
                                                      Epcoritamab_Msgs.subsub
                                                    }
                                                  />
                                                </div>
                                              </>
                                            )
                                          )}
                                        </>
                                      )}
                                  </div>
                                  <ErrorField
                                    show={medType.epco_sub_empty}
                                    message={Epcoritamab_Msgs.sub}
                                  />
                                  <ErrorField
                                    show={medType.epco_sub_oth_txt_empty}
                                    message={
                                      Epcoritamab_Msgs.epco_sub_othertext_empty
                                    }
                                  />
                                </>
                              )}
                          </div>
                        ))}
                        <div>
                          <label>
                            <input
                              type="radio"
                              id="medicationNone_"
                              name="medicationNone"
                              checked={epcoritamabDetails.medicationNone}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">None</span>
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="medicationUnk"
                              id="medicationUnk_"
                              checked={epcoritamabDetails.medicationUnk}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.epco_parent_unknown}
                        message={CommonError_Msgs.UnknownSelect}
                      />
                      <ErrorField
                        show={hardErrList.epco_parent_empty}
                        message={Epcoritamab_Msgs.parent}
                      />
                      <ErrorField
                        show={hardErrList.epco_parent_othertext_empty}
                        message={Epcoritamab_Msgs.epco_parent_othertext_empty}
                      />
                    </div>

                    {/* New changes loop based on parent */}

                    <div>
                      {medicationTypes?.map((medicationType, idx) =>
                        medicationType.subMedicationTypes?.map(
                          (subMedicationType, subidx) =>
                            subMedicationType.subSubMedicationTypes
                              .filter((subSubType) => subSubType.isSelected)
                              ?.map((data, subsubidx) => (
                                <>
                                  {data.epcorsupportivecareDetails?.map(
                                    (edata, eidx) => (
                                      <div
                                        key={eidx}
                                        className="loop-section-ul"
                                      >
                                        <div className="loop-head">
                                          <span className="question-weight">
                                            {GetOrdinal(eidx + 1)}
                                            {""}
                                            {data.name}{" "}
                                            {subMedicationType.name == "Other"
                                              ? subMedicationType.subMedicationTypeOther
                                              : subMedicationType.name}
                                          </span>
                                          {eidx !== 0 && (
                                            <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() =>
                                                  deleteRow(
                                                    idx,
                                                    subidx,
                                                    data.subSubMedicationTypeID,
                                                    eidx
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>

                                        <div className="sub-question-bot-sapce answer-pad-left">
                                          <div className="question question-weight">
                                            <span>Q52.</span>
                                            <span className="quest-text-pad">
                                              What was the date of the{" "}
                                              {GetOrdinalInWord(eidx + 1)}
                                              {""} {data.name}{" "}
                                              {subMedicationType.name == "Other"
                                                ? subMedicationType.subMedicationTypeOther
                                                : subMedicationType.name}{" "}
                                              dose the patient received?
                                            </span>
                                          </div>
                                          <div className="double-dig-answer">
                                            <div className="date-field-padding">
                                              <input
                                                type="radio"
                                                id={
                                                  "supportive_careRd_" +
                                                  idx +
                                                  "_" +
                                                  subidx +
                                                  "_" +
                                                  data.subSubMedicationTypeID +
                                                  "_" +
                                                  eidx
                                                }
                                                name={
                                                  "supportive_careRd_" +
                                                  idx +
                                                  "_" +
                                                  subidx +
                                                  "_" +
                                                  data.subSubMedicationTypeID +
                                                  "_" +
                                                  eidx
                                                }
                                                value={1}
                                                checked={
                                                  edata.supportive_careRd === 1
                                                }
                                                onChange={(e) =>
                                                  handleGlucoRepeaterFieldChange(
                                                    //idx,subindex,subSubMedicationTypeID, index
                                                    e,
                                                    idx,
                                                    subidx,
                                                    data.subSubMedicationTypeID,
                                                    eidx
                                                  )
                                                }
                                              />
                                              <span className="radio-text-padding">
                                                <DateControl
                                                  ctrlId={`supportive-care-date_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  HandleDateChange={
                                                    handleGlucoRepeaterDateChange
                                                  }
                                                  date={
                                                    edata.supportive_care_date
                                                  }
                                                  removeErrMsgPadding={true}
                                                  // hideUnk={true}
                                                  showUnkInfoTxt={true}
                                                />
                                              </span>
                                            </div>

                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={
                                                    "supportive_careRd_" +
                                                    idx +
                                                    "_" +
                                                    subidx +
                                                    "_" +
                                                    data.subSubMedicationTypeID +
                                                    "_" +
                                                    eidx
                                                  }
                                                  name={
                                                    "supportive_careRd_" +
                                                    idx +
                                                    "_" +
                                                    subidx +
                                                    "_" +
                                                    data.subSubMedicationTypeID +
                                                    "_" +
                                                    eidx
                                                  }
                                                  value={99}
                                                  checked={
                                                    edata.supportive_careRd ===
                                                    99
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Unknown{" "}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                          <ErrorField
                                            show={edata.supportive_careRdEmpty}
                                            message={
                                              Epcoritamab_Msgs.supportivecareEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={edata.supportive_care_dtEmpty}
                                            message={
                                              Epcoritamab_Msgs.supportiveCareDateEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={
                                              edata.supportive_careUnkselect
                                            }
                                            message={
                                              CommonError_Msgs.UnknownSelect
                                            }
                                          />

                                          <ErrorField
                                            show={
                                              edata.supportive_care_dtAfterIndex
                                            }
                                            message={Epcoritamab_Msgs.After_index_dt.replace(
                                              "{id}",
                                              GetOrdinalInWord(eidx + 1)
                                            )}
                                          />
                                          <ErrorField
                                            show={
                                              edata.supportive_care_dtBefore_discnt_dt
                                            }
                                            message={Epcoritamab_Msgs.Before_discnt_dt.replace(
                                              "{id}",
                                              GetOrdinalInWord(eidx + 1)
                                            )}
                                          />
                                        </div>

                                        <div className="sub-question-bot-sapce answer-pad-left">
                                          <div className="question question-weight">
                                            <span>Q53.</span>
                                            <span className="quest-text-pad">
                                              What was the quantity of this dose
                                              of {data.name}{" "}
                                              {subMedicationType.name == "Other"
                                                ? subMedicationType.subMedicationTypeOther
                                                : subMedicationType.name}
                                              ?
                                            </span>
                                          </div>
                                          <div className="double-dig-answer">
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosage_quantityRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosage_quantityRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={1}
                                                  checked={
                                                    edata.dosage_quantityRd ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Specify dosage:{" "}
                                                  <input
                                                    name={`dosage_quantity_txt_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                    className="sub-input-dash"
                                                    type="text"
                                                    value={
                                                      edata.dosage_quantity_txt
                                                    }
                                                    onChange={(e) =>
                                                      handleGlucoRepeaterFieldChange(
                                                        e,
                                                        idx,
                                                        subidx,
                                                        data.subSubMedicationTypeID,
                                                        eidx
                                                      )
                                                    }
                                                    onKeyDown={(e) => {
                                                      // Allow backspace, arrow keys, and delete
                                                      if (
                                                        [
                                                          "Backspace",
                                                          "ArrowLeft",
                                                          "ArrowRight",
                                                          "Delete",
                                                          "Tab",
                                                        ].includes(e.key)
                                                      ) {
                                                        return;
                                                      }
                                                      // Prevent non-numeric characters
                                                      if (
                                                        !/[0-9]/.test(e.key)
                                                      ) {
                                                        e.preventDefault();
                                                        return;
                                                      }
                                                      const value =
                                                        e.target.value;
                                                      const selectionStart =
                                                        e.target.selectionStart;
                                                      const newValue =
                                                        value.slice(
                                                          0,
                                                          selectionStart
                                                        ) +
                                                        e.key +
                                                        value.slice(
                                                          e.target.selectionEnd
                                                        );
                                                    }}
                                                    maxLength={100}
                                                    id={idx}
                                                  />
                                                </span>
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosage_quantityRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosage_quantityRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={99}
                                                  checked={
                                                    edata.dosage_quantityRd ===
                                                    99
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />
                                                <span className="radio-text-padding">
                                                  Unknown{" "}
                                                </span>
                                              </label>
                                            </div>
                                          </div>

                                          <ErrorField
                                            show={edata.dosage_quantityRdEmpty}
                                            message={
                                              Epcoritamab_Msgs.dosage_quantityRdEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={
                                              edata.dosage_quantity_txtEmpty
                                            }
                                            message={
                                              Epcoritamab_Msgs.q47TxtEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={edata.dose_quantityRange}
                                            message={
                                              Epcoritamab_Msgs.doseQuantityLimit
                                            }
                                          />

                                          <ErrorField
                                            show={edata.dose_quantity_Unkselect}
                                            message={
                                              CommonError_Msgs.UnknownSelect
                                            }
                                          />
                                        </div>
                                        <div className="sub-question-bot-sapce answer-pad-left">
                                          <div className="question question-weight">
                                            <span>Q54.</span>
                                            <span className="quest-text-pad">
                                              What was the frequency of this
                                              dose of {data.name}{" "}
                                              {subMedicationType.name == "Other"
                                                ? subMedicationType.subMedicationTypeOther
                                                : subMedicationType.name}
                                              ?
                                            </span>
                                          </div>
                                          <div className="double-dig-answer">
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={1}
                                                  checked={
                                                    edata.dosefrequencyRd === 1
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Daily
                                                </span>
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={2}
                                                  checked={
                                                    edata.dosefrequencyRd === 2
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Every other day
                                                </span>
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={3}
                                                  checked={
                                                    edata.dosefrequencyRd === 3
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Weekly
                                                </span>
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={4}
                                                  checked={
                                                    edata.dosefrequencyRd === 4
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />

                                                <span className="radio-text-padding">
                                                  Other, specify:{" "}
                                                  <input
                                                    name={`dosefrequency_oth_txt_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                    className="input-dash"
                                                    type="text"
                                                    value={
                                                      edata.dosefrequency_oth_txt
                                                    }
                                                    onChange={(e) =>
                                                      handleGlucoRepeaterFieldChange(
                                                        e,
                                                        idx,
                                                        subidx,
                                                        data.subSubMedicationTypeID,
                                                        eidx
                                                      )
                                                    }
                                                    maxLength={100}
                                                    id={`dosefrequency_oth_txt_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  />
                                                </span>
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`dosefrequencyRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={99}
                                                  checked={
                                                    edata.dosefrequencyRd === 99
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />
                                                <span className="radio-text-padding">
                                                  Unknown{" "}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                          <ErrorField
                                            show={edata.dosefrequencyRdEmpty}
                                            message={
                                              Epcoritamab_Msgs.dosefrequencyRdEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={
                                              edata.dosefrequency_oth_txtEmpty
                                            }
                                            message={
                                              Epcoritamab_Msgs.q47TxtEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={edata.dosefrequency_Unkselect}
                                            message={
                                              CommonError_Msgs.UnknownSelect
                                            }
                                          />
                                        </div>

                                        <div className="sub-question-bot-sapce answer-pad-left">
                                          <div className="question question-weight">
                                            <span>Q55.</span>
                                            <span className="quest-text-pad">
                                              What was the duration prescribed
                                              for this dose of {data.name}{" "}
                                              {subMedicationType.name == "Other"
                                                ? subMedicationType.subMedicationTypeOther
                                                : subMedicationType.name}
                                              ? Please estimate to the best of
                                              your ability.
                                            </span>
                                          </div>
                                          <div className="double-dig-answer">
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`durationprescribedRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`durationprescribedRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={1}
                                                  checked={
                                                    edata.durationprescribedRd ===
                                                    1
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />
                                                <span className="radio-text-padding">
                                                  Duration:{" "}
                                                  <input
                                                    name={`durationprescribed_txt_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                    className="sub-input-dash"
                                                    type="text"
                                                    value={
                                                      edata.durationprescribed_txt
                                                    }
                                                    onChange={(e) =>
                                                      handleGlucoRepeaterFieldChange(
                                                        e,
                                                        idx,
                                                        subidx,
                                                        data.subSubMedicationTypeID,
                                                        eidx
                                                      )
                                                    }
                                                    onKeyDown={(e) => {
                                                      // Allow backspace, arrow keys, and delete
                                                      if (
                                                        [
                                                          "Backspace",
                                                          "ArrowLeft",
                                                          "ArrowRight",
                                                          "Delete",
                                                          "Tab",
                                                        ].includes(e.key)
                                                      ) {
                                                        return;
                                                      }
                                                      // Prevent non-numeric characters
                                                      if (
                                                        !/[0-9]/.test(e.key)
                                                      ) {
                                                        e.preventDefault();
                                                        return;
                                                      }
                                                      const value =
                                                        e.target.value;
                                                      const selectionStart =
                                                        e.target.selectionStart;
                                                      const newValue =
                                                        value.slice(
                                                          0,
                                                          selectionStart
                                                        ) +
                                                        e.key +
                                                        value.slice(
                                                          e.target.selectionEnd
                                                        );
                                                    }}
                                                    maxLength={10}
                                                    id={`durationprescribed_txt_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  />
                                                </span>
                                                days
                                              </label>
                                            </div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="radio"
                                                  id={`durationprescribedRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  name={`durationprescribedRd_${idx}_${subidx}_${data.subSubMedicationTypeID}_${eidx}`}
                                                  value={99}
                                                  checked={
                                                    edata.durationprescribedRd ===
                                                    99
                                                  }
                                                  onChange={(e) =>
                                                    handleGlucoRepeaterFieldChange(
                                                      e,
                                                      idx,
                                                      subidx,
                                                      data.subSubMedicationTypeID,
                                                      eidx
                                                    )
                                                  }
                                                />
                                                <span className="radio-text-padding">
                                                  Unknown{" "}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                          <ErrorField
                                            show={
                                              edata.durationprescribedRdEmpty
                                            }
                                            message={
                                              Epcoritamab_Msgs.durationprescribedRdEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={
                                              edata.durationprescribed_txtEmpty
                                            }
                                            message={
                                              Epcoritamab_Msgs.q47TxtEmpty
                                            }
                                          />
                                          <ErrorField
                                            show={
                                              edata.durationprescribedUnkselect
                                            }
                                            message={
                                              CommonError_Msgs.UnknownSelect
                                            }
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}

                                  {data.epcorsupportivecareDetails.length <
                                    10 &&
                                    data.epcorsupportivecareDetails.length >
                                      0 && (
                                      <div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text">
                                          <a
                                            onClick={() =>
                                              addRow(
                                                idx,
                                                subidx,
                                                data.subSubMedicationTypeID
                                              )
                                            }
                                          >
                                            <img
                                              width="18px"
                                              src="../Assets/images/plus.png"
                                              alt=""
                                            />
                                          </a>
                                          <a
                                            onClick={() =>
                                              addRow(
                                                idx,
                                                subidx,
                                                data.subSubMedicationTypeID
                                              )
                                            }
                                            className="radio-text-padding"
                                          >
                                            Click here to add another {""}
                                            {data.name}{" "}
                                            {subMedicationType.name == "Other"
                                              ? subMedicationType.subMedicationTypeOther
                                              : subMedicationType.name}
                                            {/* {type.replace(/([A-Z])/g, " $1")} */}
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                </>
                              ))
                        )
                      )}
                    </div>

                    {tocilizumabChecked && (
                      <>
                        {/* Q51. */}
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q57.</span>
                            <span className="quest-text-pad">
                              Did the patient receive any doses of tocilizumab?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value={1}
                                  checked={
                                    epcoritamabDetails.dosesOfTocilizumabNumOfDoses ===
                                    1
                                  }
                                  name="dosesOfTocilizumabNumOfDoses"
                                  id="dosesOfTocilizumabNumOfDoses_Yes"
                                  onClick={HandleFieldChange}
                                />
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value={99}
                                  name="dosesOfTocilizumabNumOfDoses"
                                  id="dosesOfTocilizumabNumOfDoses_Unk"
                                  checked={
                                    epcoritamabDetails.dosesOfTocilizumabNumOfDoses ===
                                    99
                                  }
                                  onClick={HandleFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                            {/* <ErrorField
                        show={softErrList.q40Unk_err}
                        message={CommonMsgs.Unknown}
                      /> */}
                            <ErrorField
                              show={
                                hardErrList.dosesOfTocilizumabNumOfDoses_Empty
                              }
                              message={
                                Epcoritamab_Msgs.epco_doses_of_tocilizumab_empty
                              }
                            />
                            <ErrorField
                              show={
                                softErrList.epco_doses_of_tocilizumab_unknown
                              }
                              message={CommonError_Msgs.UnknownSelect}
                            />
                            {/* <ErrorField
                                                                show={hardErrList.dosesOfTocilizumabNumOfDosesEmpty}
                                                                message={"Please choose one of the options."}
                                                            />

                                    <ErrorField
                                      show={softErrList.dosesOfTocilizumabNumOfDosesUnk_err}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            /> */}
                          </div>
                        </div>

                        {/* loop 52 */}
                        {epcoritamabDetails.dosesOfTocilizumabNumOfDoses ===
                          1 && (
                          <div className="dependent-section">
                            {doseOfTocilizumabDetails &&
                              doseOfTocilizumabDetails.length > 0 &&
                              doseOfTocilizumabDetails.map((data, index) => (
                                <div key={index} className="loop-section-ul">
                                  <div className="loop-head">
                                    <span className="question-weight">
                                      {GetOrdinal(index + 1)} Dose
                                    </span>
                                    {index !== 0 && (
                                      <div className="delete-btn-div">
                                        <img
                                          width="16px"
                                          src="../Assets/images/Icon-material-delete.png"
                                          alt=""
                                          onClick={() =>
                                            DeleteDoseOfTocilizumabRow(index)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>

                                  {/* Q52. */}
                                  <div className="sub-question-bot-sapce answer-pad-left">
                                    <div className="question question-weight">
                                      <span>Q58.</span>
                                      <span className="quest-text-pad">
                                        What best describes the site of care
                                        where the patient received the{" "}
                                        <span className="question-weight">
                                          {GetOrdinal(index + 1)}{" "}
                                        </span>
                                        {""} dose of tocilizumab?
                                      </span>
                                    </div>
                                    <div className="double-dig-answer">
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={1}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 1}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Outpatient clinic
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={2}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 2}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Hospital
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={3}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 3}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            At home
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={4}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 4}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Other, specify:{" "}
                                            <input
                                              className="input-dash"
                                              name={"siteOfCare_other"}
                                              value={data.siteOfCare_other}
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                              type="text"
                                              maxLength={100}
                                              id={index}
                                            />
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label>
                                          <input
                                            type="radio"
                                            value={99}
                                            name={"siteOfCare_rd" + index}
                                            checked={data.siteOfCare_rd === 99}
                                            onChange={HandleRepeaterFieldChange}
                                            id={index}
                                          />
                                          <span className="radio-text-padding">
                                            Unknown
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <ErrorField
                                    show={data.siteOfCare_other_unk_soft}
                                    message={CommonError_Msgs.UnknownSelect}
                                  />
                                  <ErrorField
                                    show={data.siteOfCare_rd_empty}
                                    message={Epcoritamab_Msgs.epco_siteofcare}
                                  />
                                  <ErrorField
                                    show={data.siteOfCare_other_empty}
                                    message={
                                      Epcoritamab_Msgs.epco_siteofcare_othertext
                                    }
                                  />
                                </div>
                              ))}
                            {doseOfTocilizumabDetails &&
                              doseOfTocilizumabDetails.length < 10 && (
                                <div className="sub-question-bot-sapce-ul mt-2">
                                  <div className="answer-list-text">
                                    <a onClick={AddDoseOfTocilizumabRow}>
                                      <img
                                        width="18px"
                                        src="../Assets/images/plus.png"
                                        alt=""
                                      />
                                    </a>
                                    <a
                                      onClick={AddDoseOfTocilizumabRow}
                                      className="radio-text-padding"
                                    >
                                      Click here to add another dose of
                                      Tocilizumab
                                    </a>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )}

                      </>
                    )}



{(epcoritamabDetails.q40Discont === 1 || epcoritamabDetails.q40Discont === 99) && (
<div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q59.</span>
                        <span className="quest-text-pad">
                        Did the patient receive additional lines of therapy after epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="receivedAdditionaltmtSelect_1"
                              name="receivedAdditionaltmt"
                              value={1}
                              // checked={true}
                              checked={epcoritamabDetails.receivedAdditionaltmt === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="receivedAdditionaltmtSelect_0"
                              name="receivedAdditionaltmt"
                              value={0}
                              checked={epcoritamabDetails.receivedAdditionaltmt === 0}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <label>
                            <input
                              type="radio"
                              id="receivedAdditionaltmtSelect_99"
                              name="receivedAdditionaltmt"
                              value={99}
                              checked={epcoritamabDetails.receivedAdditionaltmt === 99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={softErrList.receivedAdditionaltmtSoft}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.receivedAdditionaltmtEmpty}
                        message={Epcoritamab_Msgs.receivedAdditionaltmtEmpty}
                      />
                    </div>
)}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Epcoritamab;
